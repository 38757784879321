export default {
  showMore: 'See More',
  hideMore: 'Hide',
  meau: {
    nav: 'Home',
    nav1: 'Use DBC',
    nav1_1: 'Purchase GPU Cloud Services',
    nav1_2: 'Deploy POS Nodes for Rewards',
    nav1_3: 'Vote for POS Nodes',
    nav1_4: 'Become a GPU Miner for Rewards',
    nav1_5: 'Apply to be a Council Member',
    nav1_6: 'Vote for Council Candidates',
    nav1_7: 'Participate in Referendums',
    nav2: 'Learn',
    nav2_1: 'What is DeepBrainChain?',
    nav2_2: 'What are GPUs?',
    nav2_3: 'DeepBrainChain Development History',
    nav2_4: 'Whitepaper',
    nav2_5: 'Introduction to DBC DAO Autonomy',
    nav2_6: 'About the Team',
    nav3: 'Developers',
    nav3_1: 'Powerful API for Renting GPUs',
    nav3_2: 'How to Become a Cloud Cafe Brand',
    nav3_3: 'How to Become a Cloud Gaming Console Distributor',
    nav3_4: 'How to Convert AAA Games into Cloud Games',
    nav3_5: 'How to Apply for Council Treasury Funds',
    nav4: 'Wallet',
    nav4_1: 'DBCWallet',
    nav4_2: 'PolkaWallet',
    nav4_3: 'ERC20 Wallet',
    nav4_4: 'MathWallet',
    nav4_5: 'ImToken',
    nav4_6: 'TrustWallet',
    nav4_7: 'MetaMask',
    nav5: 'WIKI',
    nav6: 'Buy DBC',
    nav7: 'Community',
    nav8: 'DBC Scan',
    nav9: 'AI Developer',
    nav9_1: 'What is a decentralized AI product?',
    nav9_2: 'How to develop a decentralized AI product?',
    nav9_3: 'How to deploy a token smart contract?',
    nav9_4: 'How to develop a payment-related smart contract?',
    nav9_5: 'How to develop a GPU mining-related smart contract?',
    nav9_6: 'How to develop an AI model container?',
    nav9_7: 'How to perform full-process testing?',
    nav9_8: 'How to perform basic process testing?',
    nav10: 'Miner',
    nav10_1: 'How to participate in DBC token mining?',
    nav10_2: 'How to participate in DBC and DeepLink dual mining?',
    nav10_3: 'How to participate in DBC and AI token multi-mining?',
    nav10_4: 'How to participate in AI token mining only?',
    nav10_5: 'How to participate in DBC and other GPU token multi-mining?',
  },
  home: {
    cont1: {
      text1: 'Welcome to',
      // text2: 'DeepBrain Chain',
      // text3: 'DeepBrain Chain is a decentralized high-performance GPU computing network that can scale infinitely. Its goal is to become the most widely used GPU computing infrastructure in the AI+Metaverse era worldwide.',
      // text4: 'Established in 2017, DeepBrain Chain Foundation and DeepBrain Chain Council jointly promote the development of DBC.',
      // text5: 'The DBC blockchain mainnet was launched on May 20, 2021, and the GPU computing mainnet of DBC was officially launched on November 22, 2021.',
      
      text2: 'DeepBrainChain 2.0',
      text2_1: 'DBC = AI + DePIN +GPU',
      text3: "DeepBrain Chain 2.0 is the world's first AI public chain, building decentralized AI infrastructure, compatible with the EVM smart contract standard, and supporting the decentralized operation of AI models",
      text4: 'DeepBrain Chain was established in 2017, and both the DeepBrain Chain Foundation and the DeepBrain Chain Council jointly promote the development of DBC',
      text5: 'In 2021, the DBC 1.0 GPU distributed computing power network went live, and  the DBC 2.0 AI public blockchain testnet already launched in August 2024'
    },
    cont2: {
      title1: 'Huawei, DeepBrain Chain, and Polygon Jointly Establish Metaverse and Web3 Alliance',
      text1: 'Watch Video',
      title2: 'DBC GPU Computing Mainnet Launched',
      text2: 'Mainnet GPU Computing Information',
      title3: 'AI Computing Network',
      text3: 'DBC Pitch Deck',

      title4: 'DeepLink Public Sale is',
      title4_1: 'LIVE',
      text4: 'Join Now'
    },
    // cont3: {
    //   title: 'DBC Application Ecology',
    //   text: 'Anyone can build their own GPU cloud service platform based on DBC',
    //   title1: 'AI Training',
    //   text1: 'AI training refers to using large amounts of data and algorithms to train neural networks. The purpose of training is to obtain a model that can make predictions, namely the weights and parameters of the neural network. It is estimated that by 2024, the market size of GPU servers for AI training will reach $12 billion, with a compound annual growth rate of 25% over the next 5 years.',
    //   btn1_1: 'Haibao Cloud',
    //   btn1_2: 'Hycons Cloud',
    //   title2: 'AI Inference',
    //   text2: 'AI inference refers to using trained neural networks to analyze and predict new data. The purpose of inference is to use the trained model to infer various conclusions from new data, namely the output and results of the neural network. It is estimated that by 2024, the market size of GPU servers for AI inference will reach $8 billion, with a compound annual growth rate of 35% over the next 5 years.', 
    //   title3: 'Cloud Gaming',
    //   text3: "Cloud gaming services allow games to be rendered and processed through cloud-based GPU servers, and then stream the game images to players' devices. Cloud gaming allows any AAA game to run on any device. The cloud gaming market is growing rapidly, with an estimated market size of $20.93 billion by 2030, with a compound annual growth rate of 45.5%",
    //   btn3_1: 'DeepLink',
    //   title4: 'Visual Rendering',
    //   text4: 'Visual rendering solutions are mainly applied in the fields of movies and 3D animation. The global market size was $723.7 million in 2023, and is expected to grow rapidly at a compound annual growth rate of 17.3%, reaching $3.57 billion by 2033.',
    //   btn4_1: 'Hycons Cloud',
    //   title5: 'Cloud Cafe',
    //   text5: 'Cloud cafes are a new type of cybercafe service based on cloud computing technology. In cloud cafes, games and applications actually run on remote GPU servers, and then stream to the computers in the cybercafe via the Internet in real-time. Cybercafe operators do not need to invest in high-performance GPU hardware computers, thereby greatly saving hardware investment costs. As of 2023, there were over 200,000 cybercafes worldwide, with a total of 10 million computers. The current market share of cloud cafes is less than 1%, and the market growth rate exceeds 100% annually over the next 5 years.',
    //   btn5_1: 'ZestCloud',
    //   btn5_2: 'Tikeren Cloud',
    //   title6: 'ZK Mining',
    //   text6: 'Filecoin, Aleo, ETH L2 ZK computing, etc., require GPU servers for zero-knowledge proof calculations'
    // },
    cont3: {
      title: 'DBC 2.0 AI Ecosystem Showcase',
      text: 'Any AI project can develop decentralized AI based on DBC 2.0, issue its own tokens, and run AI models permanently without the possibility of being shut down.',
      title1: 'DeepLink',
      text1: 'DeepLink Protocol is a decentralized AI cloud gaming protocol, combining AI and blockchain technology to provide ultra-low latency game rendering technology.',
      btn1_1: 'DeepLink',
      title2: 'DecentralGPT',
      text2: 'DecentralGPT is a decentralized LLM AI inference network. DecentralGPT supports a variety of open-source large language models. It is committed to building a safe, privacy-protective, democratic, transparent, open-source, and universally accessible AGI.',
      btn2_1: 'DecentralGPT',
      title3: 'SuperImage',
      text3: 'SuperImage has multiple latent text-to-image diffusion models for generating photo-realistic images given any text input, fostering autonomous freedom to produce incredible imagery, empowering billions of people to create stunning art within seconds.',
      btn3_1: 'SuperImage',
      title4: 'DRCpad',
      text4: 'The First Primary Market For Trading AI Nodes. Focuses on the node trading platform of the DBC AI public chain and screens high-quality AI projects.',
      btn4_1: 'DRCpad',
      title5: 'Hycons',
      text5: 'HYCONS CLOUD’S BUSINESS PARTNER: AI, autonomous driving, bio medicine, cloud gaming, metaverse, cloud XR, AR, VR visual rendering, climate simulation. Anyone who needs a GPU can become a HYCONS partner.',
      btn5_1: 'Hycons Cloud',
      btn5_2: 'Tikeren Cloud',
      title6: 'DBC Block Explorer',
      text6: 'The mainnet blockchain explorer can check the number of wallet holders, mainnet wallet balances, and DBC mainnet wallet transfer information.',
      btn6_1: 'DBC Block Explorer',
      title7: 'DBCSCAN',
      text7: 'DBCSCAN is the EVM explorer of the DBC AI public chain. It is now online, supporting the testnet operation, and can query transactions and deploy smart contracts.',
      btn7_1: 'DBCSCAN',
      title8: 'DBCWallet',
      text8: 'DBCWallet is a full-featured wallet maintained by the DBC Foundation. It supports POS voting rewards, POS staking, council elections and voting, treasury proposal voting, and referendums.',
      btn8_1: 'DBCWallet',
    },
    cont4: {
      title: 'DBC Today',
      text: 'Latest Network Statistics',
      text1: 'Number of GPUs',
      text2: 'GPU Rental Rate',
      text3: 'Amount of DBC Staked on GPUs',
      text4: 'Amount of DBC Consumed in GPU Rentals',
      text5: 'Total DBC Issued',
      text6: 'Number of DBC Holders Addresses',
      text7: 'Amount of DBC Staked for Staking',
      text8: 'Council DAO Treasury Funds'
    },
    cont5: {
      title: 'Why do AI developers choose us?',
      text: 'Anyone can build their own GPU cloud service platform based on DBC.',
      block1: {
        title: 'Free GPU Usage',
        text: 'AI developers can use GPUs for free simply by issuing their own tokens.',
      },
      block2: {
        title: 'Shorten R&D Cycle',
        text: 'It can help AI developers shorten the R&D time for decentralized AI from 3 years to 3 months, greatly reducing development costs.',
      },
      block3: {
        title: 'Smart Contract Deployment',
        text: 'Supports AI developers to deploy their own smart contracts and issue their own tokens.',
      },
      block4: {
        title: 'High TPS, Low Gas',
        text: 'A block every 6 seconds, supporting over 1000 TPS, with gas fees as low as 0.001 DBC.',
      },
      block5: {
        title: 'Open Source and Permissionless',
        text: 'Any cloud platform can build its own GPU cloud service platform based on DBC.',
        text1: 'Serve customers in their specific fields without permission.',
      },
      block6: {
        title: 'Decentralized AI Model Deployment',
        text: 'Supports decentralized deployment of AI models, ensuring the model never goes down or stops running. Truly decentralized model operation brings user trust and a completely transparent mechanism.',
      }
    },
    cont6: {
      title: 'Value of DBC Token',
      block1: {
        text: 'There are a total of 10 billion DBC tokens, with a fixed supply that will never increase. The entire supply will be issued over approximately 100 years.',
      },
      block2: {
        text: 'The DBC token is the only token in the DBC network.',
        text1: 'Every time a user rents GPU, they need to purchase DBC tokens from exchanges or other sources, and then pay a certain amount of DBC to the DeepBrainChain blockchain network to obtain the right to use GPU.',
      },
      block3: {
        text: "DBC follows a deflationary model. When the total number of GPUs in the DeepBrainChain network is within 5,000, 30% of the user's rental fees are destroyed. When it exceeds 5,000, 70% are destroyed, and when it exceeds 10,000, 100% are destroyed.",
        text1: 'The DBC paid by users needs to be purchased from exchanges or other sources. Each time a user rents GPU, the circulating supply of DBC in the market decreases.',
      },
      block4: {
        text: 'DBC POS super nodes need to stake DBC for block rewards. The current total amount of DBC staked in the entire network is {stakeNum}, accounting for {ratio}% of the total issued DBC.',
      },
      block5: {
        text: 'Miners need to stake DBC to provide GPUs. Each card requires a stake of 100,000 DBC or the equivalent of up to $800 in DBC. This means that the more GPUs there are in the DeepBrainChain network, the more DBC will be staked. The current total amount of DBC staked by GPU miners in the entire network is {stakeNum}, accounting for {ratio}% of the total issued DBC.',
      },
      block6: {
        text: 'DBC Token is the governance token of DeepBrainChain.',
        text1: 'The Council DAO selects 21 council members every 4 months from all candidates.',
        text2: 'Candidates with the highest number of DBC token votes among the top 21 can be elected.',
        text3: 'Each DBC token equals one vote.',
        text4: 'The Council DAO collectively manages the treasury funds to support the ecosystem development of DeepBrainChain.',
      }
    },
    cont7: {
      title: 'Where to Trade DBC',
      text1: 'DBC Precision: 15, Token Symbol: DBC, ERC20 and BEP20 DBC Contract Address:',
      text2: '0x1F735f84b07cc20E9aC471C291a87b5A2428d518'
    },
    cont8: {
      title: 'Token Economic Model',
      text1: 'Current Daily Issuance of DBC GPU Computing Power Rewards: 1,095,900 coins, DBC Mainnet POS Nodes Output Daily: 273,900 coins',
      table: {
        tr1: 'Usage',
        tr2: 'Category',
        tr3: 'Subtotal',
        tr4: 'Amount (Billion)',
        tr5: 'Circulation (Billion)',
        tr6: 'To Be Released (Billion)',
        tr7: 'Note',
        tbody1: {
          text1: 'Early Sale',
          text2: 'Sold to professional investors or AI companies for DBC ecosystem service usage rights',
        },
        tbody2: {
          text: 'Foundation and Ecosystem',
          text1: 'Foundation',
          text2: '1 billion unlocked from 2018 to 2021 (250 million each year) with the remaining 15 years unlocking, unlocking 49 million each year',
        },
        tbody3: {
          text1: 'Council',
          text2: 'The entire council is elected by the community, and DBC is fully managed by the council, unlocking over 15 years, unlocking 51 million each year, starting from January 2022',
        },
        tbody4: {
          text1: 'Team',
          text2: 'After listing on the market, 10% is locked for 1 month, and the remaining 10% is unlocked each year for a total of 10 years, starting from 2018',
        },
        tbody5: {
          text1: 'Pre-Mainnet Computing Power Incentives',
          text2: 'Used to incentivize miners to join the GPU to DBC computing power network before the mainnet goes live',
        },
        tbody6: {
          text: 'Node Rewards',
          text1: 'Super Nodes',
          text2: 'Super nodes receive rewards by accounting for blocks. For the first 3 years, they receive 1 billion coins each year, then 50 million coins each year for 5 years, and then the rewards halve every 5 years. Rewards start from May 2021',
        },
        tbody7: {
          text1: 'Computing Power Nodes',
          text2: 'Computing power nodes receive online rewards for contributing computing power. For the first 3 years, they receive 400 million coins each year, then 200 million coins each year for 5 years, and then the rewards halve every 5 years. Rewards start from August 2021',
        },  
        tbody8: {
          text1: 'Total',
        }
      }
    },
    cont9: {
      title: 'Investment Institutions and Partners'
    },
    cont10: {
      title: 'Development History & Roadmap'
    },
    cont12: {
      title: 'Media Coverage'
    },
    cont13: {
      title: 'Community Activities'
    }
  },
  buyDBC: {
    title: 'Purchase DBC',
    buy: 'Buy',
    see: 'See',
    block1: {
      title: 'Purchase DBC on Centralized Exchanges'
    },
    block2: {
      title: 'Purchase DBC on Decentralized Exchanges'
    },
    block3: {
      title: 'Purchase DBC on Swap Platforms',
      text: 'Can swap directly through your wallet'
    },
    block4: {
      title: 'Purchase DBC on Third-Party Cloud Platforms',
    }
  },
  buyGpuServer: {
    title: 'Using DBC Token to Purchase GPU Cloud Services',
    text1: 'DeepBrain Chain is a decentralized high-performance GPU computing network that can be infinitely expanded. Providers from all over the world add GPU servers to the DBC network and stake 100,000 or $800 equivalent worth of DBC to ensure the stability and availability of GPU servers. Third-party developers can develop their own cloud platforms based on DBC to provide services to users. Developers only need to serve users without having to purchase GPU servers themselves. All GPU servers can be automatically accessed from the DBC network.',
    text2: 'Users can rent GPUs with DBC on the cloud platform or use fiat currency to rent GPUs. If fiat currency is used to rent GPUs, the cloud platform will automatically convert fiat currency into DBC to rent GPUs in the DeepBrain Chain.',
    text3: 'DBC Ecological Application Services'
  },
  getReward: {
    title: 'Deploy POS Block Production Nodes to Get Rewards',
    text: 'DBC adopts the POS consensus mechanism. Currently, there are a total of 41 consensus block production nodes. Every 24 hours, 41 nodes are elected from all nodes as block production nodes. The selected nodes can generate rewards of 272,500 DBC every 24 hours. Election rules: Nodes ranked in the top 41 in terms of their self-staked DBC and the DBC voted for them.',
    seedetails: 'See Details',
    cont: {
      title: 'Become a DBC Verification Node'
    },
    procedure1: {
      title1: 'Machine Configuration',
      text1: 'Memory: 8GB',
      text2: 'CPU: 2 Cores',
      text3: 'Hard Disk: 100GB',
    },
    procedure2: {
      title1: 'Generate Funding Account (can be skipped if you already have a funding account)',
      text1: 'Reference:',
      text2: 'How to Generate an Account'
    },
    procedure3: {
      title1: 'Get dbc-chain Binary',
      text1: 'Method 1: Use Precompiled Version',
      text2: 'If unable to run properly, use Method 2 to compile it yourself:',
      text3: 'Method 2: Compile from Source Code',
      text4: '# Install Dependencies',
      text5: '# Compile dbc-chain'
    },
    procedure4: {
      title1: 'Run Synchronization Node',
      text1: 'If you are compiling from source code, the executable file path is:',
      text2: 'After synchronization is complete, close the program with Control + C. You can judge whether the synchronization has been completed based on the comparison between target and best. When the difference between target and best is small (e.g., within 100), it can be considered that synchronization has been completed.',
      text3: 'Parameter Description:',
      text4: '--base-path: Specifies the directory where the blockchain stores data. If not specified, the default path will be used. If the directory does not exist, it will be automatically created for you. If the directory already contains blockchain data, an error will occur. In this case, you should choose a different directory or clear the contents of this directory.',
      text5: '--pruning=archive: Start the blockchain in archive mode.',
      text6: '--bootnodes: Specify the boot node address.',
    },
    procedure5: {
      title1: 'Run Node as a Validator',
      text1: 'After completing step 3 of synchronizing node data, close the synchronization command. Then run the node as a validator:',
      text2: 'If you are compiling from source code, the executable file path is:',
      text3: 'Note that --name YourNodeName here is to set the name of your node. You can give your node a unique and easily recognizable name, which others will be able to see on the network.',
      text4: 'Generate rotateKey',
      text5: 'Run the following command on the machine running the validator node.'
    },
    procedure6: {
      title1: 'Set Bond Amount',
      text1: 'Open',
      text2: 'You will see your balance:',
      text3: 'Navigate to Network > Staking > Account actions > Stash account,',
      text4: 'Set the bond amount (make sure there is enough balance in your account to send transactions in addition to the amount bonded):',
      text5: 'Explanation:',
      text6: 'Stash account: Your funding account, here we bond 45 DBC, make sure there is at least this much balance in your account',
      text7: 'Controller account: This account should also have a small amount of DBC to send transactions to start and stop the validator',
      text8: 'Value bonded: How much DBC you want to bond/stake, note that you do not need to bond all the balance in the account, and you can increase the bonded amount later.',
      text9: 'Payment destination: Rewards earned by the validator will be sent to this account. This account can be set to any account. Where,',
      text10: 'Stash account (increase the amount at stake): Profits are paid to the stash account and staked',
      text11: 'Stash account (do not increase the amount at stake): Profits are paid to the stash account without staking'
    }
  },
  voteReward: {
    title: 'Earn Rewards by Voting for POS Nodes',
    text: 'Each super node receives approximately 6700 DBC rewards per day. If a node you vote for is selected as a super node, you can also earn rewards. The reward amount is calculated as (1 - the commission rate set by the voter) * my voting quantity / total voting quantity * 272,500. For example, if the total voting quantity for the super node I voted for is 20 million DBC and I voted 1 million DBC with a commission rate of 10%, then my daily reward quantity would be 90% * 1 million / 20 million * 6700 = 301. You can earn approximately 110,000 DBC rewards per year.',
    seedetails: 'See Details',
    cont: {
      title: 'Become a DBC Nominee'
    },
    procedure1: {
      title1: 'Create a Funding Account (Skip if You Already Have One)',
      text1: 'Reference:',
      text2: 'How to Create an Account'
    },
    procedure2: {
      title1: 'Acquire Some DBC',
      text1: 'Open',
      text2: 'You will be able to see your balance:'
    },
    procedure3: {
      title1: 'Set the Bond Amount',
      text1: 'Navigate to Network > Staking > Account Actions > Stash Account,',
      text2: 'In the pop-up, select the Stash Account, Controller Account, and enter the Bond Amount, then click Next.',
    }
  },
  gpuMiner:{
    title: 'Become a GPU Miner and Earn Rewards',
    text: 'In the DBC ecosystem, miners play a crucial role by providing GPU servers, which are the foundation of the DBC network. Currently, there are 1.09 million DBC rewards given to GPU providers every day. Miners receive both reward and rental income. Miners with fewer than 5,000 GPUs receive 70% of rental income, while those with more than 5,000 but fewer than 10,000 GPUs receive 30% of rental income from users. Miners with over 10,000 GPUs do not receive rental income, but their reward income increases by 30% whenever their machines are rented. Each GPU needs to stake 100,000 DBC or an equivalent of $800 USD in DBC, whichever is lower.',
    seedetails: 'View Detailed Rules',
    cont: {
      title: 'Monthly Earnings Calculation Formula for GPU Machines',
      text: 'Monthly earnings calculation formula: (Machine hash rate / Total network hash rate) * 1,095,890 * DBC price * Regional coefficient * Large model coefficient * 1.3 (30% increase in rented hash rate) * 30 + (Machine hash rate * Regional coefficient * Large model coefficient * 0.508 * 70%)',
      text1: 'Machine Model:',
      text2: 'Number of Graphics Cards:',
      text3: 'Number of Memory Units:',
      text4: 'Region:',
      text5: 'Please Select',
      text6: 'Current monthly earnings:',
      text7: 'USD equivalent DBC = ',
      text8: 'On-chain reward income:',
      text9: 'DBC (equivalent to ',
      text10: 'USD, Current DBC Price:',
      text11: 'USD) + Rental Income: ',
      text12: 'USD equivalent DBC'
    },
    country: {
      country1: 'China',
      country2: 'North America',
      country3: 'South America',
      country4: 'Europe',
      country5: 'Middle East',
      country6: 'South Korea',
      country7: 'Japan',
      country8: 'Taiwan, China',
      country9: 'Hong Kong and Macau, China',
      country10: 'Malaysia',
      country11: 'Indonesia',
      country12: 'Singapore',
      country13: 'Vietnam'
    }
  },
  applyCouncil: {
    title: 'apply to become a council member',
    text: 'The DBC Council is the DBC DAO organization, currently managing over 800 million DBC Tokens in the DBC Treasury, with 4.25 million DBC entering the Treasury every month, continuing until 2036. The council consists of 21 members, with elections held every 4 months. The top 21 candidates by vote count are selected.',
    text1: 'As a council member, you can vote on proposals for the treasury funds. Any proposal requires the approval of at least 13 out of the 21 council members to pass. If the votes do not exceed 13, the Chief Council Member can vote on behalf of the members who did not vote.',
    text2: 'Being a council member is likely to gain trust from the community and other council members. When a council member proposes a treasury fund proposal, it is more likely to be approved.',
    text3: 'Any community member can initiate a fund application proposal. However, for the proposal to enter the voting process, it needs to be submitted by one of the 21 council members.',
    seedetails1: 'Council Meeting Discussion Group',
    seedetails2: 'View Council Information',
    cont: {
      title: 'Council Election Process'
    },
    procedure1: {
      title1: 'DBC Mainnet Wallet based on Polkadot',
      text1: 'Firstly, you need to have a DBC Mainnet Wallet based on Polkadot. After creating the wallet, open the DBC Mainnet address.',
      text2: 'Select the "Governance" section.',
      text3: 'In the dropdown menu, choose "Parliament".',
      text3_1: 'Inside, you will find an overview of the current Parliament election status.',
      text4: '"Seats" - Currently elected council members.',
      text5: '"Runners up" - Candidates temporarily brushed off (runners-up).',
      text6: "'Candidates' - Applicants who submitted but temporarily didn't make it to the top 20.",
      text7: 'At the top right corner of the page are "Vote" and "Submit Candidate" buttons.',
      text8: 'If you want to run for council membership, go to "Submit Candidate"; if you just want to vote, go to "Vote".'
    },
    procedure2: {
      title1: "Next, let's look at 'Voting'",
      text1: 'Upon entry, you will see your account balance.',
      text2: 'Choose your preferred candidate, enter the number of votes in the "Vote Value" field.',
      text3: '1 DBC equals 1 vote. Note that votes less than 1 DBC will not be successful.',
      text4: 'After confirming, sign and submit, enter your password for confirmation.'
    }
  },
  councilVoting: {
    title: 'Vote for Council Candidates',
    text: "The DBC Council is the DAO organization managing the DBC Treasury. Council members play a crucial role in the development of DBC's ecosystem. Every DBC holder should pay attention and participate in council voting, casting their sacred vote for their endorsed council candidates. Only candidates ranked in the top 21 in total votes can be elected as council members.",
    seedetails1: 'Council Meeting Discussion Group',
    seedetails2: 'View Council Information',
    cont: {
      title: 'How to Vote?'
    },
    procedure1: {
      title1: 'Open the Council Voting Page',
      text1: 'Open the council voting page,',
      text2: 'click on Vote (login wallet first)',
      text3: 'Set Voting Value',
      text4: 'Set the voting value, click on the council candidate, the candidate will be placed in the right box. If multiple candidates are selected, the votes will be evenly distributed among them. Finally, click vote. Note: After voting, the voting coins will be staked and cannot be transferred. Only after unstaking can transfer operations be performed. It is recommended to vote on the day before the end of the new council election, so that the staking time of coins is the shortest.',
      text5: 'The red box in the figure below shows the time remaining until the end of the new council election',
      text6: 'Cancel Vote',
      text7: 'Unstake the voting coins. You can cancel the vote at any time. Clicking the "Cancel All" button cancels the staking. However, after canceling the vote, the vote is canceled. It is recommended to cancel the vote after the end of the new council election.'
    }
  },
  referenda: {
    title: 'Participate in Public Voting',
    text: "Any technical upgrades and parameter changes in the DBC blockchain network require approval through public voting. Public voting is a crucial part of DBC community governance. Every DBC holder is encouraged to keep track of DBC's technical and product developments, participate in public voting, and even initiate proposals for public voting.",
    seedetails: 'See Public Proposals',
    cont: {
      title: 'How to Participate in DBC Mainnet Voting'
    },
    procedure1: {
      title1: '【Governance】-【Democratic Rights】Interface Introduction',
      text1: 'Proposal — Proposals initiated by the public. At the end of each initiation period (currently every 28 days), the proposal with the most support (support expressed in total dbc amount) will be passed to public voting.',
      text2: 'External — Proposals from the Council.',
      text3: 'Initiation Period — The period during which a new public vote is opened, currently set at 28 days.'
    }
  },
  learnDBC: {
    title1: 'What is',
    title2: 'DeepBrain Chain?',
    text1: 'DeepBrain Chain 2.0 is an AI public chain. AI developers can develop decentralized AI products based on DBC. AI models can run in a decentralized manner, helping to shorten the R&D time by 90%',
    text2: 'DeepBrain Chain was established in 2017, jointly promoted by DeepBrain Chain Foundation and DeepBrain Chain Council to drive the development of DBC.',
    text3: "DBC's GPU computing power mainnet will be officially launched on November 22, 2021, and the DBC AI public chain testnet will be launched in August 2024",
    text4: "DBC gathers GPU providers from all over the world through the blockchain reward mechanism to provide GPU servers to the DBC network, and then provides AI developers with GPU use through DBC distributed network technology. AI developers issue their own tokens and reward GPU providers with tokens, thereby obtaining a large number of GPU providers",
    text5: 'The more ecosystem applications there are, the greater the demand for GPUs by the ecosystem applications themselves. The more DBC transactions used daily, the more DBC destroyed, and the greater the value of DBC.',
    text6: 'For example, taking the cloud cybercafe application as an example: cloud cybercafe users need to buy coins in the trading market to use GPUs. For every additional GPU used, 30% of the coins purchased from the trading market will be destroyed. If there are 1000 cloud cybercafes, each cybercafe has 100 machines, and each machine is used for 10 hours a day, paying 0.1 USD per hour, of which 30% is destroyed. Each month, 900,000 USD worth of coins are destroyed. According to a coin price of 0.002 USDT, more than 400 million coins need to be destroyed in a month. At the same time, to support 1000 cybercafes, 70,000 machines are needed, and an additional 7 billion coins need to be staked.'
  },
  whatisGPU: {
    title1: 'What is',
    title2: 'GPU？',
    text1: 'GPU, short for Graphics Processing Unit, is a specialized computing unit designed for tasks related to graphics and video processing. Unlike CPUs (Central Processing Units), GPUs are designed specifically for parallel processing of large amounts of data.',
    block1: {
      title: 'High Parallel Performance',
      text: 'GPUs are composed of hundreds or thousands of small cores, allowing them to process a large amount of data simultaneously. For example, when rendering 3D graphics, each core can independently process a pixel or a vertex, significantly increasing processing speed.'
    },
    block2: {
      title: 'Graphics Optimization',
      text: 'Originally designed to accelerate graphics rendering, GPUs are efficient at handling tasks related to images and videos, such as texture mapping and lighting calculations.'
    },
    block3: {
      title: 'Wide Range of Applications',
      text: 'While GPUs were initially designed for gaming and professional graphics design, they are now also crucial in many other fields, especially in artificial intelligence and machine learning.'
    },
    title3: 'Gaming and Artificial Intelligence',
    title4: 'Why Do We Need GPUs?',
    text2: 'The high parallel processing capability of GPUs makes them excel in handling graphics-intensive tasks and large-scale data processing tasks, making them indispensable in gaming and artificial intelligence fields.',
    text3: 'Currently, the market value of the GPU chip leader NVIDIA exceeds $1 trillion, which is six times that of the CPU chip leader Intel, indicating a huge demand for GPUs, far exceeding that of CPUs.',
    block4: {
      title: 'Gaming',
      text: 'Games and modern gaming typically involve complex 3D graphics and physics simulations. These tasks require extensive parallel processing, making the powerful graphics processing capabilities of GPUs highly suitable. Using GPUs can achieve smoother gaming experiences and higher graphical fidelity.'
    },
    block5: {
      title: 'Artificial Intelligence and Machine Learning',
      text: 'In the field of artificial intelligence, especially in deep learning, handling large amounts of data and performing complex mathematical computations are required. These computing tasks are often parallelizable, making them highly suitable for the high parallel performance of GPUs. Using GPUs can significantly accelerate the speed of model training and inference.'
    }
  },
  historyDBC: {
    title: 'Development History of DeepBrainChain',
    title1: '2017',
    text1_1: 'The DeepBrainChain project was initiated, setting goals, visions, and the direction of technological architecture',
    text1_2: 'Completion of fundraising',
    title2: '2018',
    text2_1: 'DBC Token listed on the Huobi exchange',
    text2_2: 'DBC computing power network launched, with code open-sourced on GitHub',
    title3: '2020',
    text3_1: 'DBChain global AI developer users surpass 10,000, serving over 500 AI-related universities and labs worldwide',
    title4: '2021',
    text4_1: 'DeepBrainChain node mainnet officially launched',
    text4_2: 'DeepBrainChain mainnet browser launched',
    text4_3: 'Over 50 cloud platforms based on DeepBrainChain',
    text4_4: 'DeepBrainChain GPU computing power mainnet started public testing',
    text4_5: 'Total GPU computing power in the public testing network exceeds 1200 cards, with a rental rate of over 98%',
    text4_6: 'DeepBrainChain GPU computing power mainnet officially launched',
    title5: '2022',
    text5_1: 'Galaxian competition GPU count reaches 1500',
    text5_2: 'haibaogpu cloud platform based on dbc launched, providing GPU services for AI users',
    text5_3: 'Three South Korean pools - DBC KOREA HILLSTONE, DBC KOREA Ai-Factory, and Gines Korea Center-1 join the DBC network',
    text5_4: 'hycons cloud platform based on dbc launched in South Korea, providing GPU services for AI and cloud gaming clients',
    text5_5: 'DeepLink cloud gaming platform based on dbc launched, serving as a cloud gaming platform with remote control tools',
    title6: '2023',
    text6_1: 'Huawei, DeepBrainChain, and Polygon jointly establish the Metaverse and Web3 Alliance',
    text6_2: "The world's first ZestCloud cloud cybercafe based on DBC and DeepLink technology begins trial operation in Seoul, South Korea",
    text6_3: "The world's first TIKEREN cloud gaming machine based on DBC and DeepLink technology is unveiled in Tokyo, Japan, with a joint presentation",
    title7: '2024',
    text7_1: 'Q1',
    text7_2: '1. Development of GPU Short-Term Rental Mode',
    text7_3: '2. Launch of New Features on the Mainnet',
    text7_4: 'Q2',
    text7_5: '1. Development of Smart Contract Functionality Support',
    text7_6: '2. Enhancement of GPU Short-Term Rental Mode',
    text7_7: '3. Support for Converting GameFi Games to Cloud GameFi',
    text7_8: 'Q3',
    text7_9: '1. Support for Decentralized AIGC Projects to Develop Smart Contracts based on DBC',
    text7_10: '2. Support for Decentralized AIGC Projects to Mine using DBC GPU',
    text7_11: '3. Completion of Smart Contract Functionality Development Testing',
    text7_12: 'Q4',
    text7_13: '1. Support for Mining 3A GameFi using DBC GPU',
    text7_14: '2. Development of DBCSwap Feature, Supporting Token Trading within the DBC Ecosystem with DBC Token on-chain',
  },
  introDBC: {
    title: 'Introduction to DBC DAO Governance',
    text1: 'With the launch of the DBC mainnet in 2021, there are currently two organizations jointly promoting the development of DBC, namely the DBC Foundation and the DBC Council.',
    text2: "The DBC Foundation is the founding organization of DBC, mainly responsible for DBC's technical development and global outreach. The DeepBrainChain Foundation and the DeepBrainChain Council are two completely independent organizations with no affiliation, jointly serving the DeepBrainChain ecosystem and promoting the development of DeepBrainChain.",
    cont2: {
      block1: {
        title: 'DBC Council',
        text1: 'A DAO organization elected by the community, mainly responsible for managing treasury funds, supporting DBC ecosystem applications, developing the DBC community, and governing DBC.',
        text2: 'Currently managing a total of over 800 million DBC tokens in the DBC treasury, with 4.25 million DBC entering the treasury every month, continuing until 2036.',
        text3: 'The council consists of a total of 21 members, elected every 4 months. The top 21 candidates are selected.',
      },
      block2: {
        title: 'Becoming a Council Member',
        text1: 'Becoming a council member allows you to vote on treasury fund proposal applications. Any proposal requires the approval of 13 out of 21 council members to pass. If the number of votes is less than 13, the Chief Director can vote on behalf of non-voting directors.',
        text2: 'Becoming a council member is more likely to gain the trust of the community and other directors. When directors themselves apply for treasury fund proposals, they are more likely to be approved.',
        text3: 'Any community member can initiate a fund application proposal, but for the proposal to enter the voting process, it requires submission by one of the 21 directors.',
        btn: 'See Details'
      }
    },
    cont3: {
      title: 'Usage of DBC Treasury Funds',
      text1: 'Infrastructure Deployment',
      text1_2: 'and Continuous Operations',
      text2: 'Network Security Operations',
      text2_1: 'Monitoring Services, Continuous Auditing',
      text3: 'Ecosystem Support',
      text3_1: 'Collaboration with Friendly Chains',
      text4: 'Marketing Activities',
      text4_1: 'Advertising, Payments, Partnerships',
      text5: 'Community and Outreach Events',
      text5_1: 'Meetups, Pizza Parties, Hackerspaces',
      text6: 'Infrastructure Deployment and Continuous Operations',
      text6_1: 'Wallet and Wallet Integration, Client and Client Upgrades',
      text7: 'Exchange Listings',
      text8: 'More...'
    },
    cont4: {
      title: 'Current Top Three Directors in this Term of DAO Membership',
      job: 'Chief Director',
      reward: 'Votes:'
    },
    cont5: {
      title: 'Countdown to New Director Election',
      text1: 'Proposals',
      text2: 'In Progress',
      text3: 'Approved',
      text3_1: 'Rejected',
      text4: 'Amount Applied',
      text5: 'Applicant:',
      text6: 'End Time',
      text7: 'Hours',
      text8: 'Time Passed',
      text9: 'Agree',
      text10: 'Disagree',
      text11: 'Days'
    },
    seemore: 'See More'
  },
  aboutTeam: {
    title: 'About the Team',
    lead: 'HE YONG',
    job: 'Founder',
    intro : {
      text1: "Founder of DeepBrainChain and DeepLink, He Yong, serial entrepreneur.",
      text2: "With 14 years of experience in AI, 7 years in blockchain and distributed computing network technology development. He graduated with a bachelor's degree in Computer Science from Ocean University of China in 2008 and pursued a Master's and Ph.D. in Bioinformatics at East China Normal University in 2010.",
      text3: "Founded Jushang Intelligence in 2012 and developed the world's first Chinese AI voice assistant - Smart 360. Within 3 years, the software had over 17 million downloads.",
      text4: "Launched the world's first AI smart speaker - Xiao Zhi Super Speaker in 2014.",
      text5: "Founded DeepBrain Chain in May 2017, currently serving hundreds of AI, cloud gaming, and cybercafes clients.",
      text6: "Established DeepLink in 2020, where DeepLink Protocol is a decentralized AI cloud gaming protocol, collaborating with DeepBrain Chain to serve global cybercafes and AAA game companies."
    },
    application: {
      title: 'Job Application',
      desc: 'The DeepBrainChain Foundation currently has over 50 full-time and part-time research and market staff, with personnel spread across countries including Singapore, China, the United States, Russia, South Korea, Taiwan, Japan, India, Vietnam, Bangladesh, and Nigeria'
    },
    weekly: 'Recent 16 Issues of DBC Bi-weekly Report',
    see: 'View Details',
    seemore: 'See More'
  },
  deployCloud: {
    title: 'Powerful API for Renting GPUs',
    step1: {
      text1: 'Rent GPU via On-chain API',
      text2: 'API Node Address: xxxx, or deploy your own blockchain API sync node. Deployment documentation:',
      text3: 'Rent On-chain Machine',
      text4: 'Confirm Availability and Rent',
      text5: 'Renewal',
    },
    step2: {
      text1: 'Rent Whole GPU Machine',
      text2: 'Create Virtual Machine',
      text3: 'Restart Virtual Machine',
      text4: 'Confirm Availability and Rent',
      text5: 'Renewal',
    },
    step3: {
      text1: 'Rent GPU Bare Metal Machine',
      text2: 'Create Machine',
      text3: 'Restart Machine',
      text4: 'Shutdown',
      text5: 'Get DeepLink Device ID and Verification Code',
      text6: 'Get Ubuntu Account and Password',
    },
    server: 'If you have any questions, please contact:'
  },
  cloudInternet: {
    title: 'How to Become a Cloud Internet Café Solution Provider',
    text: 'Currently, major internet café distribution countries globally include South Korea (6,000), China (40,000), Brazil (10,000), Indonesia (20,000), the Philippines (20,000), Vietnam (15,000), Turkey (5,000), as well as India, Mexico, the Middle East, South America, Africa, Europe, and other countries. Globally, there are over 150,000 internet cafés. However, the internet café industry has a history of 20 years and has become a traditional industry. The payback period has increased to 3-4 years, and it faces various risks. For example, the COVID-19 pandemic has led to the closure of a large number of internet cafés. Therefore, the internet café industry needs a new solution to reduce the payback period, mitigate risks, and improve operational efficiency. Thus, the cloud internet café solution is born.',
    cont2: {
      text1: 'What is ',
      text2: 'A Cloud Cybercafe Solution?',
      text3: 'A cloud cybercafe solution is a new type of cybercafe service model with ultra-low latency rendering technology. In a cloud cybercafe, games and applications are not installed directly on local computers but hosted on cloud GPU servers. Users access and play games through cloud services without the need for high-performance local hardware.',
      block1: {
        text1: 'Reduce Local Hardware Requirements',
        text2: 'Since games and applications run on cloud GPU servers, local computers do not require high-performance GPUs or large storage space. This reduces cybercafe hardware investment and maintenance costs.'
      },
      block2: {
        text1: 'Instant Access to Latest Games',
        text2: 'Users can access the latest games instantly without waiting for download and installation. All game updates and maintenance are done in the cloud, providing users with convenient and efficient experiences.'
      },
      block3: {
        text1: 'High-Performance Gaming Experience',
        text2: 'Local monitors only need to be equipped with mini PCs (around $100), and cloud cybercafes can still provide high-performance gaming experiences. This is because game computing is done on remote high-performance GPU servers.'
      },
      block4: {
        text1: 'Space and Energy Saving',
        text2: 'Lower-configured local computers occupy less space and consume less power, helping to reduce cybercafe operating costs.'
      },
      block5: {
        text1: 'Easy Management and Maintenance',
        text2: 'Cybercafe managers can more easily manage software and games because all content is centrally managed in the cloud. This reduces local maintenance work and potential technical issues.'
      }
    },
    cont3: {
      text1: 'Glowsteam Cloud Cybercafe in South Korea',
      text2: 'Actual Operating Environment of Cloud Cybercafe'
    },
    cont4: {
      text1: 'Cloud Cybercafe Solution Can Help Cybercafe Investors',
      text2: 'Increase Net Profit by 3 Times and Shorten Payback Period by 40%',
      text3: 'Click to Download Comparative Data'
    },
    cont5: {
      text1: 'How Do Cloud Cybercafe Solution Providers Profit?',
      text2: 'If the profit is calculated as 12% of the earnings per cybercafe machine, then servicing a total of 1000 cybercafes with 100,000 machines, each machine being used an average of 10 hours per day, and with an average cost of $0.8 per machine per hour, 12% equals approximately $0.1.',
      text3: 'The annual profit of a cloud cybercafe solution provider is',
      text4: '100,000 * 10 * 0.1 * 365 = $36.5 million',
      text5: 'How to Become a Cloud Cybercafe Solution Provider',
      text6: "Based on DeepBrainChain and DeepLink technologies, South Korea's HYCONS has developed a complete cloud cybercafe solution. DeepBrainChain provides GPU computing power, DeepLink offers ultra-low latency rendering technology, and HYCONS delivers the entire solution. Analogously, if we compare it to a car, DeepBrainChain provides the engine, DeepLink provides the autonomous driving system, and HYCONS provides the complete car solution. Local solution providers in various countries only need to integrate their local payment systems into HYCONS to provide services to local cybercafes, and solution providers can set their own brands. HYCONS acts solely as a technology service provider without exposing its brand.",
      text7: 'For more information, please contact:',
      text8: "service{'@'}deepbrainchain.org"
    },
      cont6: {
      title: 'Download Cloud Cybercafe Related Materials',
      text1: 'HYCONS Introduction',
      text2: 'Cloud Cybercafe Solution Profit Analysis',
      text3: 'Cloud Cybercafe Data Center Architecture Diagram',
      text4: 'Cloud Cybercafe Solution Testing and Installation Manual',
      text5: 'DeepLink Protocol Introduction'
    }
  },
  gameAgents: {
    title: 'How to Become a Cloud Gaming Console Distributor',
    text: "What is a cloud gaming console? Compared to traditional gaming consoles, cloud gaming consoles have high FPS refresh rates, achieving a smoother gaming experience. HKC Group's TIKEREN cloud gaming console is developed based on DeepBrainChain + DeepLink technology, allowing real-time connection to remote GPU high-performance computing for game rendering and calculation.",
    title1: 'TIKEREN Console Presentation',
    table: {
      th: {
        text1: 'Sort',
        text2: 'Item',
        text3: '27 inches',
        text4: '31.5 inches'
      },
      td1: {
        text1: 'Mode Type',
        text2: 'MA27H13QC1',
        text3: 'MA27H13QC1'
      },
      td2: {
        text1: 'Color Category',
        text2: 'Black',
        text3: 'Black'
      },
      td3: {
        text1: 'Display Size',
        text2: '27 inches',
        text3: '31.5 inches'
      },
      td4: {
        text1: 'Aspect Ratio',
        text2: 'Wide (16:9)',
        text3: 'Wide (16:9)'
      },
      td5: {
        text1: 'Panel Type',
        text2: 'IPS',
        text3: 'VA'
      },
      td6: {
        text1: 'Panel Resolution',
        text2: 'QHD 2560*1440',
        text3: 'QHD 2560*1440'
      },
      td7: {
        text1: 'Panel Refresh Rate',
        text2: '180Hz (Max)',
        text3: '170Hz(Max)'
      },
      td8: {
        text1: 'Responese Time',
        text2: '1mS(MPRT).GTG 1ms',
        text3: '1ms(MPRT).GTG 1ms'
      },
      td9: {
        text1: 'Color Gamut',
        text2: 'DCIP3 90%',
        text3: 'DCIP3 90%'
      },
      td10: {
        text1: 'Mainboard Solution',
        text2: 'Intel® N100 Chipset',
        text3: 'Intel® N100 Chipset'
      },
      td11: {
        text1: 'CPU/Cooler',
        text2: 'Intel N100 3.4GHz',
        text3: 'Intel N100 3.4GHz'
      },
      td12: {
        text1: 'Memory',
        text2: 'DDR4 8GB(Option Max 32G)',
        text3: 'DDR4 8GB(Option Max 32G)'
      },
      td13: {
        text1: 'Hard Disk',
        text2: 'SSD 128G(Option Max 1T)',
        text3: 'SSD 128G(Option Max 1T)'
      },
      td14: {
        text1: 'OS',
        text2: 'Windows 10/11(0ption)',
        text3: 'Windows 10/11(0ption)'
      },
      td15: {
        text1: 'GPU',
        text2: 'Intel UHD Graphics',
        text3: 'Intel UHD Graphics'
      },
      td16: {
        text1: 'Rear Interface',
        text2: '1*DC Power Connector，1*RJ45(2.5G)，4* USB 3.0，1*Audio jacks',
        text3: '1*DC Power Connector，1*HDMIN，1*HDMI OUT，1*RJ45(2.5G)，4* USB 3.0,1*Audio iacks'
      },
      td17: {
        text1: 'Side Interface',
        text2: 'USB 2.0x2，Power Button x1',
        text3: 'USB 2.0x2，Power Button x1'
      },
      td18: {
        text1: 'Wi-Fi',
        text2: '2.4G~5GHz(Wi-Fi5),BT(4.2)',
        text3: '2.4G~5GHz(Wi-Fi5),BT(4.2)'
      },
      td19: {
        text1: 'Audio',
        text2: 'Built-in Speakers*2',
        text3: 'Built-in Speakers*2'
      },
      td20: {
        text1: 'Base/Stand',
        text2: 'HAS:VESA',
        text3: 'HAS:VESA'
      },
      td21: {
        text1: 'Pivot',
        text2: 'Up, Down, Left, Right, 90° Rotation, Height Adjustment',
        text3: 'Up, Down, Left, Right, 90° Rotation, Height Adjustment'
      },
      td22: {
        text1: 'Power',
        text2: '90W',
        text3: '90W'
      },
      td23: {
        text1: 'Game Screenlmprovement',
        text2: 'Free Sync, G-Sync',
        text3: 'Free Sync, G-Sync'
      }
    },
    text1: 'Please contact via email'
  },
  convertGame: {
    title: 'How to Convert AAA Games to Cloud Games?',
    text: 'DeepLink Protocol is a decentralized ultra-low latency cloud gaming rendering protocol, any application can develop cloud gaming related products based on DeepLink Protocol, where the cloud computing power is provided by DeepBrainChain.',
    text1: 'Understanding DeepLink Protocol',
    step: {
      title1: 'Integrating Games with DeepLink Protocol',
      text1: "Contact service{'@'}deeplink.cloud",
      title2: 'DeepLink Automatically Calls DeepBrainChain GPU Servers',
      text2: 'Deploying the game to run in the cloud'
    }
  },
  applyForFunding: {
    title: 'How to Apply for Treasury Funds from the Council',
    text1: 'Before applying for funds, you can join the Council Fund Application Telegram discussion group.',
    text2: 'Telegram Discussion Group',
    cont: {
      title: 'DBC Treasury Fund Application Process',
      text: 'When an applicant requests funds from the treasury, they need to reserve 5% of the proposed amount. If the treasury proposal is approved, the reserved funds will be refunded; if the treasury proposal is rejected, the reserved funds will be penalized. Please note that users cannot withdraw their proposal after submitting a financial proposal.',
    },
    procedure: {
      text: 'Treasury Fund Application Process:',
      title1: 'Prepare Draft',
      text1: 'Prepare a draft based on the proposal template, reference the proposal template',
      text2: 'English:',
      text3: 'Chinese:',
      title2: 'Discuss Proposal Off-chain',
      text4: 'Before submitting a proposal on-chain, proposers can discuss proposals with the council and community members off-chain in any forum, such as WeChat, Telegram groups, etc., integrating multiple opinions and continuously improving the proposal. Currently, DBC has established a subreddit community DBC_Council on Reddit (https://www.reddit.com/r/DBC_Council/open in new window), which requires VPN for use in mainland China. The community has also established a section for proposal discussion [proposal_draft discussion]. Proposers can post drafts to this community and add flair [proposal_draft discussion] for discussion.',
      title3: 'Submit On-chain',
      text3_1: 'Submit the proposal on-chain at the DBC mainnet-governance-treasury page (https://www.dbcwallet.io/#/treasury), enter the requested DBC amount, and the system will automatically stake 5% of the DBC as collateral.',
      text3_2: 'Note! If the proposal is approved, the collateral will be refunded; if the proposal is rejected, the collateral will not be returned!',
      text3_3: 'Therefore, pay attention to the preparation of the proposal and submit a relatively complete and feasible proposal.',
      text3_4: 'At the same time of on-chain submission, proposers must also post the proposal content to the subreddit community DBC_Council (https://www.reddit.com/r/DBC_Council/) and add flair [proposal_submitted onchain]. After on-chain submission, you will receive the proposal number and block height. Add the [number] to your proposal post for easy on-chain-off-chain correspondence.',
      title4: 'Council Proposes and Votes',
      text4_1: 'After the applicant completes the application, it can be viewed at https://www.dbcwallet.io/#/treasury.',
      text4_2: 'At this point, the council can propose a motion for or against the application. A motion to accept the application requires the consent of 3/5 of the council; a motion to reject the application requires the consent of 1/2 of the council.',
      text4_3: 'Please note! The council needs to propose a motion in the treasury (https://www.dbcwallet.io/#/treasury) to vote in the council-motion (https://www.dbcwallet.io/#/council/motions).',
      title5: 'Voting Completed',
      text5_1: 'After the voting period ends, the council needs to manually close the motion (https://www.dbcwallet.io/#/council/motions).',
      text5_2: 'After the vote, the approved proposals will be archived to flair [proposal_approved] by the community administrators of the DBC subreddit, and the rejected proposals will be classified as flair [proposal_rejected].',
      title6: 'Funds Disbursement',
      text6_1: 'Funds are disbursed in batches, and successful proposers need to continuously update their progress. Please specify the funds needed for each stage in the proposal. The completion of the proposal will be supervised by community members (non-council members) through voting.',
      text6_2: 'Special Note: The receiving address for funds cannot be an exchange address; it needs to be an address where you control the private key, preferably an address generated by the DBC mainnet, otherwise, you will not receive the disbursed funds after approval.',
      text6_3: 'Note: The disbursal of approved fund applications will be made at the end of the spending cycle. The spending cycle can be viewed at https://www.dbcwallet.io/#/treasury (there is a countdown to the spending cycle in the upper right corner).'
    },
  },
  createwallet: {
    title: 'DBCWallet Wallet',
    text: 'DBCWallet Wallet is a full-featured wallet maintained by the DBC Foundation, which can be used to create DBC and DLC wallet addresses, participate in POS voting, participate in council election voting, participate in referendums, and more.',
    text1: 'Visit Website',
    cont2: {
      title: 'Wallet Address Creation Method',
      text1: 'To protect user asset security, wallet creation is done in plugin form',
      step: {
        title1: 'Install Google Chrome Browser',
        title2: 'Search for Polkadot Plugin',
        text2: 'Install Plugin',
        title3: 'Save Seed Phrase',
        text3: 'Open the Polkadot plugin, click on create wallet, select support for all chains, save seed phrase',
        title4: 'Refresh www.dbcwallet.io',
        text4: 'My Account page will show the wallet already created'
      }
    },
    title1: 'Wallet Creation Video'
  },
  polkaWallet: {
    title: 'PolkaWallet Wallet',
    text: 'Search for PolkaWallet on Google Play and App Store, or visit the official website: PolkaWallet.io to download.',
    text1: 'Download from PolkaWallet.io',
    // step: {
    //   title: '创建流程',
    //   text: '为了保护用户持币安全，采用插件的形式创建钱包',
    //   title1: '安装Google Chrome浏览器',
    //   title2: '搜索Polkadot插件',
    //   text2: '安装插件',
    //   title3: '保存种子',
    //   text3: '打开Polkadot插件，点击创建钱包，选择支持所有链，保存种子',
    //   title4: '刷新www.dbcwallet.io',
    //   text4: '我的账户页面会出现已经创建的钱包'
    // },
    step: {
      title: 'Creation Process',
      text1: '1. Open polkawallet.io in your browser.',
      text2: '2. Go to the Polkadot official website to download the APP wallet.',
      text3: '3. After downloading, select "New" or "Import" for the DBC mainnet wallet.',
      text4: '4. Choose the Substrate account.',
      text5: '5. For a new wallet, remember to backup the key properly.',
      text6: '6. Set the name and password, then you can enter the main interface.',
      text7: '7. Swipe left on the main interface to select DBC.',
      text8: '8. Click on the icon to send and receive mainnet DBC.',
      text9: '9. Receiving interface.',
      text10: '10. Sending and transfer interface. The gas used for mainnet wallet is DBC, so please reserve a small amount of DBC when transferring.'
    }
  },
  mathWallet: {
    title: 'MathWallet',
    text: 'Search for MathWallet on Google Play and App Store, or visit the official website: https://mathwallet.org/ to download.',
    text1: 'Download MathWallet',
    step: {
      title: 'Creation Process',
      text1: '1. Enter mathwallet.org in your browser.',
      text2: '2. After entering the official website, choose the download path.',
      text3: '3. On Android, you can directly download the installation package or go to the Google Play Store to download.',
      text4: '4. After downloading, open the wallet and manually select the DBC main network.',
      text5: '5. The DBC main network wallet is on the fourth page from the end.',
      text6: '6. You can create or import.',
      text7: '7. The password creation requires both uppercase and lowercase letters.',
      text8: '8. Please backup the mnemonic phrase.',
      text9: '9. After seeing the DBC icon appear, click to receive and send assets.',
      text10: '10. Sending interface.',
      text11: '11. Swipe left to create more wallets.',
      text12: '12. The settings in the lower right corner allow you to select the language.',
      text13: '13. In the middle bottom of the main interface, you can see the main network browser.'
    }
  },
  ERC20Wallet: {
    title: 'ERC20 Wallet',
    text1: 'MetaMask',
    text2: 'Imtoken',
    text3: 'TrustWallet',
    text4: 'Search for DBC contract address:',
    text5: 'Precision:',
  },
  ImToken: {
    title: 'ImToken Wallet',
    text: 'Visit the official website: token.im to download',
    text1: 'Download token.im',
    step: {
      title: 'Creation Process',
      text1: '1. Enter token.im in your browser',
      text2: '2. Click "Download Now" after entering',
      text3: '3. Choose to install directly or from the Google Play Store',
      text4: '4. After downloading, create a wallet in the app',
      text5: '5. After creation, it is recommended to immediately backup and securely store your keys',
      text6: '6. On the wallet main page, click the "+" sign to add a token',
      text7: '7. In the search bar, enter the DBC contract address: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. After successfully adding, return to the main page to see the DBC icon',
      text9: '9. Click on the icon to view the DBC receiving and sending addresses',
      text10: '10. When sending to others, make sure to use their ERC20 address',
    }
  },
  trustWallet: {
    title: 'TrustWallet Wallet',
    text: 'Search TrustWallet on Google Play and the App Store, or visit the official website: https://trustwallet.com/ to download',
    text1: 'Download TrustWallet',
    step: {
      title: 'Creation Process',
      text1: '1. Enter trustwallet.com in your browser',
      text2: '2. After entering, there are three download options',
      text3: '3. After downloading, create a new wallet',
      text4: '4. Please backup the new wallet immediately',
      text5: '5. After backup completion, enter the main interface',
      text6: '6. Click the small "+" icon overlapped in the upper right corner of the main interface, then click the "+" icon in the upper right corner of the current page',
      text7: '7. On the import token interface, paste the DBC contract address: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. After successful import, the DBC icon will appear',
      text9: '9. Click on "Receive" to get the current DBC wallet address',
      text10: '10. Click on "Send" to enter the sending interface, and ensure that there is enough ETH reserved for gas on the ERC20 chain to complete the transaction',
    }
  },
  metaMask: {
    title: 'MetaMask Wallet',
    text: 'Search for MetaMask on Google Play, or visit the official website: https://metamask.io/ to download',
    text1: 'Download MetaMask',
    step: {
      title: 'Creation Process',
      text1: '1. Open metamask.io in your browser',
      text2: '2. Install from Google Play Store',
      text3: '3. After downloading, import or create a wallet to enter the main interface',
      text4: '4. Click "Import Tokens" at the bottom of the interface to enter the add token page',
      text5: '5. Choose "Custom Token" and enter the DBC contract address: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text6: '6. After successfully importing, you can see the DBC icon on the main interface',
      text7: '7. Click to enter and you can see the options to receive and send DBC',
      text8: '8. Sending requires ETH in the wallet for gas'
    }
  },

  whatisAi: {
    title1: 'What is a Decentralized AI Product?',
    text1: 'A decentralized AI product refers to an AI product where all aspects run in a decentralized manner. The product mainly consists of two parts: payment settlement and model operation. When both of these parts achieve full decentralization, it is considered a decentralized AI product.',
    title2: 'What is a Centralized AI Product?',
    text2: 'A centralized AI product is one where all aspects are controlled by a centralized entity. For example, payment settlements are processed on the centralized entity’s servers, and the AI model runs on GPU servers managed by the centralized entity. In short, the centralized entity has the power to impose restrictions and make modifications at will.',
    title3: 'What is a Semi-Decentralized AI Product?',
    text3: 'A semi-decentralized AI product is one where part of it is controlled by a centralized entity, and part is decentralized. For example, payments are settled using token-based smart contracts, but the AI model runs on GPU servers controlled by the centralized entity.',
    title4: 'What’s the Difference Between a Decentralized AI Project and a Semi-Decentralized AI Project?',
    text4_1: 'The differences are as follows:',
    text4_2: 'A decentralized AI project does not rely on any centralized entity, and its token is a utility token, which is not subject to securities regulations. On the other hand, tokens issued by semi-decentralized AI projects are security tokens, subject to securities laws in various countries, which introduces potential risks.',
    text4_3: 'A decentralized AI project tends to have a longer lifespan and can be taken over by the community if the team encounters issues. This is because the model runs on GPU resources provided by miners, rather than on the centralized servers of the team.',
    title5: 'What Are the Advantages of Decentralized AI Products?',
    text5_1: '1. GPU usage is free for the project team.',
    text5_2: '2. Funds can be raised by selling GPU mining nodes.',
    text5_3: '3. The token is a utility token and is not subject to securities regulations.',
    text5_4: '4. Users can be incentivized through tokens.',
    text5_5: '5. The model runs in a decentralized way, is transparent, and is free from regulatory control, ensuring users do not worry about usage restrictions.',
    text5_6: '6. Users can participate in project governance, and even if the team disbands, the community can take over, ensuring the model service continues uninterrupted.',
  },
  developAi: {
    title: 'How to Develop a Decentralized AI Product?',
    title1: '1. How to Develop a Decentralized AI Product?',
    text1: "A decentralized AI product consists mainly of two parts: payment settlement and model operation. The payment settlement is implemented by deploying payment contracts. The model operation is divided into two parts: the mining reward contract and the AI container. The mining reward contract determines what percentage of tokens is allocated to GPU miners as rewards, encouraging more miners to deploy the project's model and earn tokens. The AI container requires the project team to package their AI model into a container image, which miners can deploy based on provided documentation.",
    title2: '2. What Does the Payment Settlement Contract Include?',
    text2_1: '1). The product’s pricing strategy, such as whether it charges per request or by monthly subscription.',
    text2_2: '2). The price of the product.',
    text2_3: '3). The permissions for a certain wallet address to use the product.',
    title3: '3. What Does the Mining Reward Contract Include?',
    text3_1: '1). The total amount of token rewards.',
    text3_2: '2). The mining mechanism, such as how many tokens can be mined per year.',
    text3_3: '3). Factors affecting the number of tokens mined, such as GPU performance, the amount of staked tokens, the number of node NFTs, mining duration, etc.',
    text3_4: '4). The rules for unlocking mined tokens.',
    title4: '4. What Does the AI Model Container Include?',
    text4_1: '1). The container runtime environment.',
    text4_2: '2). The AI model and running scripts.',
    text4_3: '3). The registration script for DBC GPU computing nodes.',
    text4_4: '4). DBC health monitoring program.',
    text4_5: '5). Interaction programs with the mining contract and payment settlement contract.',
  },
  deployToken: {
    title: 'How to Deploy a Token Smart Contract?',
    text1: '1. DBC is compatible with the ETH EVM, and the DRC20 token standard is fully compatible with ERC20. You can write smart contracts using the Solidity language.',
    text2_1: '2. DBC testnet explorer:',
    text2_2: ' Through DBCScan, you can query deployed contracts, wallet addresses, and transactions.',
    text3: '3. Specific contract deployment process:',
    text3_1: '1). Fill these dbc testnet configurations into hardhat.config.ts. After that, you can write code and test your contract.',
    text3_2: 'rpc_url: "https://rpc-testnet.dbcwallet.io"',
    text3_3: 'api_url: "https://test.dbcscan.io/api"',
    text3_4: 'browser_url: "https://test.dbcscan.io"',
    text3_5: 'network: "dbcTestnet"',
    text3_6: 'chain_id: 19850818',
    text3_7: '2). You can use the Makefile to compile, deploy, upgrade, verify, or test the contract.',
    text3_8: '3). These links may be useful for developing ERC20 contracts:',
    text4: '4. Token contract reference example:',
  },
  paymentRelated: {
    title: 'How to Develop Payment-Related Smart Contracts?',
    text1: 'Determine the pricing for different AI models. For example, the current project has three AI models with two modes: subscription and free. In the free mode, each model can be called 10 times per day, while in the subscription mode, each model can be called 10,000 times per day. The pricing for each model is specified in the contract.',
    text2: 'When a user of the project sends a request through the DBC AI distributed network to the project’s AI container, the container must have a gatekeeper program to interact with the payment settlement smart contract. This ensures that the current AI model should provide computing services to the user. Without this gatekeeper program, the AI container would return computation results for any user request, and users would not pay any fees to the smart contract.',
    text3: 'Gatekeeper program of the project team',
    text4: 'AI model container of the project team',
    text5: 'Payment settlement smart contract',
    text6: 'The gatekeeper program interacts with the smart contract to determine if the current AI model should provide computing services to the user.',
  },
  gpuMining: {
    title: 'How to Develop GPU Mining-Related Smart Contracts?',
    text1: 'The reward mechanism for GPU mining is very important, as it determines the rules by which GPU miners can receive rewards. The more attractive the reward rules, the more miners will participate by providing GPU resources. It is recommended to allocate 20%-50% of the tokens as mining rewards. A halving mechanism can be set, such as halving every 2 or 4 years. You can refer to:',
    text2: 'When a miner deploys an AI container on a GPU machine, the container must include a staking program. This staking program communicates with the DBC AI Worker Node to register or unregister. Registration indicates that the machine has started mining for the current AI project. The staking program also communicates with the GPU mining-related smart contract to send the registration success information to the smart contract, which starts counting mining rewards from that point.',
    img_text1: 'Register / Unregister',
    img_text2: 'AI Model',
    img_text3: 'Staking Program of the Project Team',
    img_text4: 'AI Model Container of the Project Team',
    img_text5: 'Automatically Submit Registration Information to the DBC Chain',
    img_text6: 'Send Successful Registration Information to the Smart Contract',
    img_text7: 'The GPU Mining-Related Contract Needs to Verify Registration on the DBC Chain',
    img_text8: 'GPU Mining-Related Smart Contract',
    text3_1: 'There are two modes for GPU machines to join the DBC network:',
    text3_2: '1. Long-term rental mode  2. Free mode',
    text3_3: 'The GPU mining smart contract needs to obtain information from the DBC chain to determine the mode in which the GPU machine is participating, and different modes should be treated differently.',
    text4: 'Handling Long-Term Rental Mode:',
    text4_1: '1) The GPU mining smart contract must first determine whether the GPU machine has already been rented.',
    text4_2: '2) The staking wallet address must be proven to be owned by the same person as the wallet address renting the GPU on the DBC network.',
    text4_3: '3) Only when the above conditions are met will the miner receive token rewards.',
    text5: 'Handling Free Mode:',
    text5_1: '1) The GPU mining smart contract must first determine whether the GPU machine has successfully joined the DBC network.',
    text5_2: '2) Only when the above conditions are met will the miner receive token rewards.',
  },
  developAIModel: {
    title: 'How to Develop an AI Model Container?',
    title1: '1. The AI model container is developed by the AI project team and deployed by GPU miners.',
    title2: '2. What Does the AI Model Container Include?',
    text2_1: '1) Container runtime environment: First, start with a clean container. ',
    text2_1_1: '(Reference tutorial for creating container images)',
    text2_2: '2) AI model and running scripts: Since models are generally large, it is not recommended to download the model in advance in the container. Instead, after starting the container, it can automatically download from a remote source, such as Huggingface.',
    text2_3: '3) Registration script for DBC GPU computing nodes: This script handles registration and deregistration. You can also set it to automatically register when the container starts and automatically deregister when it stops.',
    text2_3_1: '(Tutorial for using Worker node)',
    text2_4: '4) DBC health monitoring program. ',
    text2_5: '5) Interaction program with the mining contract and payment settlement contract: Mainly the staking program and the gatekeeper program, which can also be combined into one program.',
    block_text1: 'AI Model',
    block_text2: 'Staking Program of the Project Team',
    block_text3: 'DBC Health Monitoring Program',
    block_text4: 'Gatekeeper Program of the Project Team',
    block_text5: 'Registration Script for DBC GPU Computing Nodes',
    block_text6: 'AI Model Container',
    title3: '3. Creating an AI Model Container Image: The Process of Creating an Image',
  },
  fullProcessTesting: {
    title: 'How to Perform Full-Process Testing?',
    text1: '1. Complete the development of payment-related smart contracts.',
    text2: '2. Complete the development of GPU mining-related smart contracts.',
    text3: '3. Finish creating the AI container image.',
    text4: '4. Prepare a GPU machine capable of running the AI model. Add the machine to the DBC network using the free rental mode, then run the DBC AI Worker Node on the GPU machine, start the AI container, and register the AI container.',
    text5: '5. Set up a CPU machine with an IP address, and deploy the DBC AI Input Node on the CPU machine.',
    text6: '6. Test whether the DBC AI Input Node correctly detects the AI container.',
    text7: '7. Send user requests to the DBC AI Input Node and check if the feedback results are returned correctly.',
  },
  primaryProcessTesting: {
    title: 'How to Perform Basic Process Testing?',
    text: "Basic process testing only checks whether the AI container can properly handle user request scheduling through the distributed network and return the AI model's computation results through the distributed network.",
    text1: '1. Complete the creation of the AI container image.',
    text2: '2. Prepare a GPU machine that can run the AI model, then run the DBC AI Worker Node on the GPU machine (using the test network). After that, install the container environment, start the AI container, and register the AI container.',
    text3: '3. Set up a CPU machine with an IP address, then deploy the DBC AI Input Node on the CPU machine.',
    text4: '4. Test whether the DBC AI Input Node detects the AI container correctly.',
    text5: '5. Send user requests to the DBC AI Input Node and check if the feedback results are returned correctly.',
  },
  participateDBCMining: {
    title: 'How to Participate in DBC Token Mining?',
    text: 'Participating solely in DBC mining allows you to earn: DBC mining rewards + DBC rental income.',
    text1: 'Prepare a GPU machine that meets DBC requirements. For specific details, refer to:',
    text2: 'If you only participate in DBC Token mining, you will receive DBC mining rewards and DBC rental income (rental income is only available when there are fewer than 10,000 GPUs on the entire network).',
    text3_1: 'For mining reward inquiries, refer to:',
    text3_2: 'Monthly earnings calculation formula for the machine',
  },
  participateDBCDLCMining: {
    title: 'How to Participate in DBC and DeepLink Dual Mining?',
    text: 'Participating in DBC and DeepLink dual mining allows you to earn: DBC mining rewards - destroyed DBC rental fees + DeepLink mining rewards.',
    text1: 'Prepare a GPU machine that meets DeepLink’s requirements. For specific details, refer to: ',
    text2: 'Add the GPU machine to the DBC network in long-term rental mode, then rent the machine yourself (machines in rental status on DBC earn 30% more rewards). After that, join the DeepLink network. ',
    text3_1: 'For mining reward inquiries, refer to:',
    text3_2: 'Monthly earnings calculation formula for the machine.',
  },
  participateDBCAiMining: {
    title: 'How to Participate in DBC and AI Token Multi-Mining?',
    text: 'Participating in DBC and AI Token multi-mining allows you to earn: DBC mining rewards - destroyed DBC rental fees + AI Token rewards.',
    text1: 'Prepare a GPU machine that meets the requirements of the AI project.',
    text2: 'Add the GPU machine to the DBC network in long-term rental mode, then rent the machine yourself (machines in rental status on DBC earn 30% more rewards). After that, join the AI project mining smart contract.',
    text3_1: 'For mining reward inquiries, refer to the official website of each AI project:',
    text3_2: 'Monthly earnings calculation formula for the machine.',
  },
  participateAiMining: {
    title: 'How to Participate in AI Token Mining Only?',
    text: 'By participating in AI Token mining only, your total earnings will consist of: AI Token rewards.',
    text1: 'Prepare a GPU machine that meets the requirements of the AI project. For specific details, refer to:',
    text2: 'Add the GPU machine to the DBC network in free mode, then join the AI project mining smart contract.',
    text3_1: 'For mining reward inquiries, refer to the official website of each AI project:',
    text3_2: 'Monthly earnings calculation formula for the machine.',
  },
  participateGPUMining: {
    title: 'How to Participate in DBC and Other GPU Token Multi-Mining?',
    text1: 'Prepare a GPU machine that meets the DBC requirements. For specific details, refer to:',
    text2: 'Add the GPU machine to the DBC network in long-term rental mode, then start a virtual machine. In the virtual machine, start mining GPU projects such as Aleo, IONET, Aethir, etc. Note that in this mode, the virtual machine may be shut down at any time if a user rents the DBC machine, so it is not suitable for mining projects with penalty mechanisms.',
    text3: 'Add the GPU machine to the DBC network in long-term rental mode, then rent the machine yourself (machines in rental status on DBC earn 30% more rewards). Then start a virtual machine and begin mining GPU projects such as Aleo, IONET, Aethir, etc. In this mode, the virtual machine will not be shut down, making it suitable for any type of GPU mining project. However, note that this mode will destroy DBC rental income, so it is only profitable if DBC rewards exceed the rental loss.',
    text4_1: 'For DBC mining reward inquiries, refer to:',
    text4_2: '\n Monthly earnings calculation formula for the machine.',
  },
  footer: {
    logo: 'Download Logo',
    server: 'Service Email'
  },
}