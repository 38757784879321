export default {
  showMore: '더 보기',
  hideMore: '감추다',
  meau: {
    nav: '홈',
    nav1: 'DBC 사용',
    nav1_1: 'GPU 클라우드 서비스 구매',
    nav1_2: '보상을 위한 POS 노드 배포',
    nav1_3: 'POS 노드에 투표하기',
    nav1_4: '보상을 위해 GPU 채굴자가 되기',
    nav1_5: '이사회 회원이 되기',
    nav1_6: '이사회 후보에게 투표하기',
    nav1_7: '국민투표에 참여하기',
    nav2: '배우기',
    nav2_1: 'DeepBrainChain이란 무엇인가요?',
    nav2_2: 'GPU란 무엇인가요?',
    nav2_3: 'DeepBrainChain 개발 역사',
    nav2_4: '백서',
    nav2_5: 'DBC DAO 자치 소개',
    nav2_6: '팀 소개',
    nav3: '개발자',
    nav3_1: '강력한 GPU 대여용 API',
    nav3_2: '클라우드 카페 브랜드가 되는 방법',
    nav3_3: '클라우드 게임 콘솔 대리점이 되는 방법',
    nav3_4: 'AAA 게임을 클라우드 게임으로 변환하는 방법',
    nav3_5: '이사회 자금 신청하는 방법',
    nav4: '지갑',
    nav4_1: 'DBC 지갑',
    nav4_2: 'Polka 지갑',
    nav4_3: 'ERC20 지갑',
    nav4_4: 'Math 지갑',
    nav4_5: 'ImToken',
    nav4_6: 'Trust 지갑',
    nav4_7: 'MetaMask',
    nav5: '위키',
    nav6: 'DBC 구매',
    nav7: '커뮤니티',
    nav8: 'DBC Scan',
    nav9: 'AI 개발자',
    nav9_1: '탈중앙화 AI 제품이란?',
    nav9_2: '탈중앙화 AI 제품을 개발하는 방법은?',
    nav9_3: '토큰 스마트 계약을 배포하는 방법?',
    nav9_4: '결제 관련 스마트 계약을 개발하는 방법?',
    nav9_5: 'GPU 채굴 관련 스마트 계약을 개발하는 방법?',
    nav9_6: 'AI 모델 컨테이너를 개발하는 방법?',
    nav9_7: '전체 프로세스 테스트를 수행하는 방법?',
    nav9_8: '기본 프로세스 테스트를 수행하는 방법?',
    nav10: '채굴자',
    nav10_1: 'DBC 토큰 채굴에 참여하는 방법?',
    nav10_2: 'DBC와 DeepLink 듀얼 채굴에 참여하는 방법?',
    nav10_3: 'DBC와 AI 토큰 다중 채굴에 참여하는 방법?',
    nav10_4: 'AI 토큰 채굴에만 참여하는 방법?',
    nav10_5: 'DBC 및 기타 GPU 토큰 다중 채굴에 참여하는 방법?',
  },
  home: {
    cont1: {
      text1: '환영합니다',
      // text2: 'DeepBrain Chain',
      // text3: 'DeepBrain Chain은 무한으로 확장 가능한 탈중앙화 고성능 GPU 컴퓨팅 네트워크로, 목표는 전 세계에서 AI+메타버스 시대에 가장 널리 사용되는 GPU 컴퓨팅 인프라가 되는 것입니다.',
      // text4: '2017년에 설립된 DeepBrain Chain Foundation과 DeepBrain Chain Council은 DBC의 개발을 공동으로 촉진합니다.',
      // text5: 'DBC 블록체인 메인넷은 2021년 5월 20일에 시작되었으며, DBC의 GPU 컴퓨팅 메인넷은 2021년 11월 22일에 공식적으로 시작되었습니다.',
      
      text2: 'DeepBrainChain 2.0',
      text2_1: 'DBC = AI + DePIN +GPU',
      text3: 'DeepBrain Chain 2.0은 세계 최초의 인공지능 공용 블록체인으로, 탈중앙화된 인공지능 인프라를 구축하며 EVM 스마트 계약 표준을 지원하고 AI 모델의 탈중앙화 실행을 지원합니다',
      text4: 'DeepBrainChain은 2017년에 설립되었으며, DeepBrainChain 재단과 DeepBrainChain 이사회가 공동으로 DBC의 발전을 추진하고 있습니다',
      text5: '2021년에는 DBC 1.0 GPU 분산 컴퓨팅 파워 네트워크가 출시되었고, 2024년 8월에는 DBC 2.0 AI 퍼블릭 체인 테스트넷이 출시되었습니다',
    },
    cont2: {
      title1: '화웨이, DeepBrain Chain, 폴리곤이 함께 메타버스와 웹3 연합을 설립했습니다',
      text1: '동영상 보기',
      title2: 'DBC GPU 컴퓨팅 메인넷이 온라인으로 공개되었습니다',
      text2: '메인넷 GPU 컴퓨팅 정보',
      title3: 'AI 컴퓨팅 네트워크',
      text3: 'DBC 피치 덱',
      
      title4: 'DeepLink 공개 판매가',
      title4_1: '진행 중',
      text4: '지금 참여하세요'
    },
    // cont3: {
    //   title: 'DBC 응용 생태계',
    //   text: '누구나 DBC를 기반으로 자신의 GPU 클라우드 서비스 플랫폼을 구축할 수 있습니다',
    //   title1: 'AI 훈련',
    //   text1: 'AI 훈련은 대량의 데이터와 알고리즘을 사용하여 신경망을 훈련하는 것을 의미합니다. 훈련의 목적은 예측이 가능한 모델, 즉 신경망의 가중치와 매개 변수를 얻는 것입니다. 2024년까지 AI 훈련용 GPU 서버 시장 규모는 120억 달러에 이를 것으로 추정되며, 다음 5년 동안 복합 연간 성장률은 25%입니다.',
    //   btn1_1: 'Haibao Cloud',
    //   btn1_2: 'Hycons Cloud',
    //   title2: 'AI 추론',
    //   text2: 'AI 추론은 훈련된 신경망을 사용하여 새로운 데이터를 분석하고 예측하는 것을 의미합니다. 추론의 목적은 훈련된 모델을 사용하여 새로운 데이터로부터 다양한 결론을 내리는 것입니다. 2024년까지 AI 추론용 GPU 서버 시장 규모는 80억 달러에 이를 것으로 추정되며, 다음 5년 동안 복합 연간 성장률은 35%입니다.',
    //   title3: '클라우드 게임',
    //   text3: '클라우드 게임 서비스를 사용하면 게임이 클라우드 기반 GPU 서버를 통해 렌더링 및 처리되고, 그런 다음 게임 이미지가 플레이어의 장치로 스트리밍됩니다. 클라우드 게임을 사용하면 모든 AAA 게임이 모든 장치에서 실행될 수 있습니다. 클라우드 게임 시장은 빠르게 성장하고 있으며, 2030년까지 시장 규모는 209.3억 달러로 추정되며, 다음 5년 동안 연평균 성장률은 45.5%입니다.',
    //   btn3_1: 'DeepLink',
    //   title4: '시각 렌더링',
    //   text4: '시각 렌더링 솔루션은 주로 영화 및 3D 애니메이션 분야에 적용됩니다. 2023년에 전세계 시장 규모는 7.237억 달러였으며, 연평균 성장률이 17.3%인 급격한 성장이 예상되며, 2033년까지 35.7억 달러에 달할 것으로 예상됩니다.',
    //   btn4_1: 'Hycons Cloud',
    //   title5: '클라우드 카페',
    //   text5: '클라우드 카페는 클라우드 컴퓨팅 기술을 기반으로 한 새로운 유형의 사이버 카페 서비스입니다. 클라우드 카페에서 게임과 응용 프로그램은 실제로 원격 GPU 서버에서 실행되고, 그런 다음 인터넷을 통해 실시간으로 사이버 카페의 컴퓨터로 스트리밍됩니다. 사이버 카페 운영자는 고성능 GPU 하드웨어 컴퓨터에 투자할 필요가 없으므로 하드웨어 투자 비용을 크게 절약할 수 있습니다. 2023년까지 전 세계에 20만 개 이상의 사이버 카페가 있으며, 총 1000만 대의 컴퓨터가 있습니다. 현재 클라우드 카페의 시장 점유율은 1% 미만이며, 다음 5년 동안 연간 시장 성장률이 100%를 넘습니다.',
    //   btn5_1: 'ZestCloud',
    //   btn5_2: 'Tikeren Cloud',
    //   title6: 'ZK 채굴',
    //   text6: 'Filecoin, Aleo, ETH L2 ZK 컴퓨팅 등은 제로 지식 증명 계산에 GPU 서버가 필요합니다'
    // },
    cont3: {
      title: 'DBC 2.0 AI 에코시스템 전시',
      text: '어떤 AI 프로젝트든 DBC 2.0을 기반으로 분산형 AI를 개발하고, 자체 토큰을 발행하며, 영구적으로 AI 모델을 실행할 수 있고, 중단될 수 없습니다.',
      title1: 'DeepLink',
      text1: 'DeepLink 프로토콜은 AI와 블록체인 기술을 결합하여 초저지연 게임 렌더링 기술을 제공하는 분산형 AI 클라우드 게이밍 프로토콜입니다.',
      btn1_1: 'DeepLink',
      title2: 'DecentralGPT',
      text2: 'DecentralGPT는 분산형 LLM AI 추론 네트워크입니다. 다양한 오픈소스 대규모 언어 모델을 지원하며 안전하고, 프라이버시를 보호하며, 민주적이고, 투명하고, 오픈소스이며, 누구나 접근할 수 있는 AGI를 구축하는 데 전념하고 있습니다.',
      btn2_1: 'DecentralGPT',
      title3: 'SuperImage',
      text3: 'SuperImage는 어떤 텍스트 입력에도 포토리얼리스틱한 이미지를 생성하기 위한 여러 잠재적인 텍스트-이미지 확산 모델을 보유하고 있어, 경이로운 이미지를 제작할 수 있는 자율적인 자유를 촉진하고 수십억 명의 사람들이 몇 초 만에 멋진 예술을 창조할 수 있도록 합니다.',
      btn3_1: 'SuperImage',
      title4: 'DRCpad',
      text4: 'AI 노드를 거래하기 위한 첫 번째 1차 시장으로, DBC AI 퍼블릭 체인의 노드 거래 플랫폼에 중점을 두고 고품질 AI 프로젝트를 선별합니다.',
      btn4_1: 'DRCpad',
      title5: 'Hycons',
      text5: 'HYCONS CLOUD의 비즈니스 파트너: AI, 자율주행, 바이오 의약, 클라우드 게임, 메타버스, 클라우드 XR, AR, VR 시각화 렌더링, 기후 시뮬레이션. GPU가 필요한 사람은 누구나 HYCONS 파트너가 될 수 있습니다.',
      btn5_1: 'Hycons Cloud',
      title6: 'DBC 메인넷 블록 탐색기',
      text6: '메인넷 블록체인 탐색기를 통해 지갑 홀더 수, 메인넷 지갑 잔액 및 DBC 메인넷 지갑 전송 정보를 확인할 수 있습니다.',
      btn6_1: 'DBC 메인넷 블록 탐색기',
      title7: 'DBCSCAN',
      text7: 'DBCSCAN은 DBC AI 퍼블릭 체인의 EVM 탐색기입니다. 현재 온라인으로, 테스트넷 운영을 지원하며, 거래 조회 및 스마트 컨트랙트 배포를 할 수 있습니다.',
      btn7_1: 'DBCSCAN',
      title8: 'DBCWallet',
      text8: 'DBCWallet은 DBC 재단이 유지하는 풀기능 지갑으로, POS 투표 보상, POS 스테이킹, 의회 선거 및 투표, 재무 제안 투표 및 국민투표를 지원합니다.',
      btn8_1: 'DBCWallet',
    },
    cont4: {
      title: 'DBC 오늘',
      text: '최신 네트워크 통계',
      text1: 'GPU 수',
      text2: 'GPU 임대율',
      text3: 'GPU에 넣은 DBC 양',
      text4: 'GPU 임대료로 소비된 DBC 양',
      text5: '총 DBC 발행량',
      text6: 'DBC 보유자 주소 수',
      text7: '스테이킹을 위해 넣은 DBC 양',
      text8: '이사회 DAO 자금'
    },
    cont5: {
      title: 'AI 개발자들이 우리를 선택하는 이유는 무엇일까요?',
      text: '누구나 DBC를 기반으로 자체 GPU 클라우드 서비스 플랫폼을 구축할 수 있습니다.',
      block1: {
        title: 'GPU 무료 사용',
        text: 'AI 개발자는 자체 토큰을 발행하여 GPU를 무료로 사용할 수 있습니다.',
      },
      block2: {
        title: '개발 기간 단축',
        text: 'AI 개발자가 분산형 AI의 개발 기간을 3년에서 3개월로 단축하여 개발 비용을 크게 줄일 수 있습니다.',
      },
      block3: {
        title: '스마트 계약 배포',
        text: 'AI 개발자가 자체 스마트 계약을 배포하고 자체 토큰을 발행할 수 있도록 지원합니다.',
      },
      block4: {
        title: '높은 TPS, 낮은 가스',
        text: '6초마다 블록이 생성되고, 1000 이상의 TPS를 지원하며, 가스 수수료는 0.001 DBC로 낮습니다.',
      },
      block5: {
        title: '오픈 소스 및 무허가',
        text: '어떤 클라우드 플랫폼이라도 DBC를 기반으로 자체 GPU 클라우드 서비스 플랫폼을 구축할 수 있습니다.',
        text1: '특정 분야의 고객에게 허가 없이 서비스를 제공합니다.',
      },
      block6: {
        title: '분산형 AI 모델 배포',
        text: 'AI 모델의 분산형 배포를 지원하여 모델이 중단 없이 영구적으로 작동합니다. 진정한 분산형 모델 운영으로 사용자 신뢰와 완전히 투명한 메커니즘을 제공합니다.',
      }
    },
    cont6: {
      title: 'DBC 토큰 가치',
      block1: {
        text: '총 100 억 DBC 토큰이 있으며, 고정된 공급량으로 증가하지 않습니다. 총 공급량은 약 100 년 동안 발행됩니다.',
      },
      block2: {
        text: 'DBC 토큰은 DBC 네트워크에서 유일한 토큰입니다.',
        text1: '사용자가 GPU를 임대 할 때마다 거래소 또는 기타 출처에서 DBC 토큰을 구매하고 GPU 사용 권한을 얻기 위해 DeepBrainChain 블록 체인 네트워크에 일정량의 DBC를 지불해야합니다.',
      },
      block3: {
        text: 'DBC는 통화 가치가 하락하는 모델을 따릅니다. DeepBrainChain 네트워크의 총 GPU 수가 5,000 미만인 경우 사용자의 임대료의 30%가 파괴됩니다. 5,000을 초과하면 70%가 파괴되고, 10,000을 초과하면 100%가 파괴됩니다.',
        text1: '사용자가 지불 한 DBC는 거래소 또는 기타 출처에서 구매해야합니다. 사용자가 GPU를 임대 할 때마다 시장에서의 DBC 순환 공급량이 감소합니다.',
      },
      block4: {
        text: 'DBC POS 슈퍼 노드는 블록 보상을 위해 DBC를 스테이크해야합니다. 현재 전체 네트워크에서 스테이킹 된 DBC의 총액은 {stakeNum}이며, 발행 된 DBC의 총액의 {ratio}%를 차지합니다.',
      },
      block5: {
        text: '마이너는 GPU를 제공하기 위해 DBC를 스테이크해야합니다. 각 카드에는 100,000 DBC 또는 최대 800 달러 상당의 DBC를 스테이크해야합니다. 즉, DeepBrainChain 네트워크 내 GPU가 많을수록 스테이크되는 DBC가 많아집니다. 현재 전체 네트워크에서 GPU 마이너가 스테이크 한 DBC의 총액은 {stakeNum}이며, 발행 된 DBC의 총액의 {ratio}%를 차지합니다.',
      },
      block6: {
        text: 'DBC 토큰은 DeepBrainChain의 거버넌스 토큰입니다.',
        text1: '의회 DAO는 모든 후보자 중에서 4 개월마다 21 명의 의회 회원을 선출합니다.',
        text2: '상위 21위 후보자 중 DBC 토큰 투표 수가 가장 높은 후보자가 선출됩니다.',
        text3: '각 DBC 토큰은 1 표와 동일합니다.',
        text4: '의회 DAO는 DeepBrainChain의 생태계 개발을 지원하기 위해 국고 자금을 공동으로 관리합니다.',
      }
    },
    cont7: {
      title: 'DBC 거래소',
      text1: 'DBC 정밀도: 15, 토큰 심볼: DBC, ERC20 and BEP20 DBC 계약 주소:',
      text2: '0x1F735f84b07cc20E9aC471C291a87b5A2428d518'
    },
    cont8: {
      title: '토큰 경제 모델',
      text1: '현재 DBC GPU 컴퓨팅 파워 보상의 일일 발행량: 1,095,900 코인, DBC 메인넷 POS 노드의 일일 출력량: 273,900 코인',
      table: {
        tr1: '사용 방법',
        tr2: '카테고리',
        tr3: '소계',
        tr4: '수량 (10억)',
        tr5: '유통 (10억)',
        tr6: '해제 예정 (10억)',
        tr7: '참고',
        tbody1: {
          text1: '초기 판매',
          text2: '전문 투자자 또는 AI 기업에게 DBC 에코 시스템 서비스 사용 권한 판매',
        },
        tbody2: {
          text: '재단 및 생태계',
          text1: '재단',
          text2: '2018년부터 2021년까지 10억 개의 잠금 해제 (매년 25억 개)와 남은 15년에 10억 개의 잠금 해제 (매년 0.49억 개)',
        },
        tbody3: {
          text1: '이사회',
          text2: '이사회 전체는 커뮤니티에 의해 선출되며, DBC는 완전히 이사회에 의해 관리되며 15 년 동안 잠금이 해제되며 매년 0.51 억 개가 잠금 해제됩니다. 2022년 1월부터 시작',
        },
        tbody4: {
          text1: '팀',
          text2: '시장에 상장 된 후 10 %는 1 개월 동안 잠금되고 나머지 10 %는 10 년 동안 매년 잠금이 해제되며 2018년부터 시작됩니다.',
        },
        tbody5: {
          text1: '메인넷 전 알고리즘 보상',
          text2: '메인넷이 가동되기 전에 채굴자들이 DBC의 계산 능력 네트워크에 가입하기 위한 인센티브로 사용됩니다',
        },
        tbody6: {
          text: '노드 보상',
          text1: '슈퍼 노드',
          text2: '슈퍼 노드는 블록 회계를 통해 보상을받습니다. 처음 3 년간 매년 10 억 개씩, 다음 5 년간 매년 5 억 개씩, 그 후 5 년마다 보상이 절반으로 줄어듭니다. 보상은 2021 년 5 월부터 시작됩니다.',
        },
        tbody7: {
          text1: '컴퓨팅 파워 노드',
          text2: '컴퓨팅 파워 노드는 컴퓨팅 파워를 기여하여 온라인 보상을받습니다. 처음 3 년간 매년 40 억 개씩, 다음 5 년간 매년 20 억 개씩, 그 후 5 년마다 보상이 절반으로 줄어듭니다. 보상은 2021 년 8 월부터 시작됩니다.',
        },
        tbody8: {
          text1: '합계',
          } 
      }
    },
    cont9: {
      title: '투자 기관 및 파트너'
    },
    cont10: {
      title: '개발 내역 및 로드맵'
    },
    cont12: {
      title: '미디어 보도'
    },
    cont13: {
      title: '커뮤니티 활동'
    }
  },
  buyDBC: {
    title: 'DBC 구매',
    buy: '구매',
    see: '보기',
    block1: {
      title: '중앙 집중식 거래소에서 DBC 구매'
    },
    block2: {
      title: '탈중앙화 거래소에서 DBC 구매'
    },
    block3: {
      title: '스왑 플랫폼에서 DBC 구매',
      text: '지갑을 통해 직접 스왑 가능'
    },
    block4: {
      title: '서드 파티 클라우드 플랫폼에서 DBC 구매'
    }
  },
  buyGpuServer: {
    title: 'DBC 토큰을 사용하여 GPU 클라우드 서비스 구매',
    text1: 'DeepBrain Chain은 무한히 확장 가능한 분산형 고성능 GPU 컴퓨팅 네트워크로, 전 세계의 제공자가 GPU 서버를 DBC 네트워크에 추가하고 10만 달러 또는 800달러 상당의 DBC를 스테이크하여 GPU 서버의 안정성과 가용성을 보장합니다. 타사 개발자는 DBC를 기반으로 자체 클라우드 플랫폼을 개발하여 사용자에게 서비스를 제공할 수 있습니다. 개발자는 GPU 서버를 구매할 필요없이 사용자에게 서비스를 제공하기만 하면 됩니다. 모든 GPU 서버는 DBC 네트워크에서 자동으로 액세스할 수 있습니다.',
    text2: '사용자는 클라우드 플랫폼에서 DBC로 GPU를 임대하거나 법정 통화로 GPU를 임대할 수 있습니다. 법정 통화를 사용하여 GPU를 임대하는 경우 클라우드 플랫폼은 법정 통화를 DBC로 자동 변환하여 DeepBrain Chain의 GPU를 임대합니다.',
    text3: 'DBC 생태 애플리케이션 서비스'
  },
  getReward: {
    title: 'POS 블록 생성 노드 배포하여 보상 받기',
    text: 'DBC는 POS 합의 메커니즘을 채택합니다. 현재 총 41 개의 합의 블록 생성 노드가 있습니다. 매 24 시간마다 모든 노드에서 41 개의 노드가 블록 생성 노드로 선출됩니다. 선택된 노드는 24 시간마다 272,500 DBC의 보상을 생성할 수 있습니다. 선거 규칙 : 자체 스테이크 된 DBC 및 그에 대해 투표 된 DBC로 상위 41 위의 노드.',
    seedetails: '세부 정보보기',
    cont: {
      title: 'DBC 확인 노드로 참여'
    },
    procedure1: {
      title1: '기계 구성',
      text1: '메모리 : 8GB',
      text2: 'CPU : 2 코어',
      text3: '하드 디스크 : 100GB',
    },
    procedure2: {
      title1: '자금 계정 생성 (이미 자금 계정이있는 경우 건너 뛸 수 있음)',
      text1: '참고 :',
      text2: '계정 생성 방법'
    },
    procedure3: {
      title1: 'dbc-chain 이진 파일 가져오기',
      text1: '방법 1: 사전에 컴파일된 버전 사용',
      text2: '정상적으로 실행되지 않으면 직접 컴파일하려면 방법 2를 사용하십시오:',
      text3: '방법 2: 소스 코드에서 컴파일',
      text4: '# 종속성 설치',
      text5: '# dbc-chain 컴파일'
    },
    procedure4: {
      title1: '동기화 노드 실행',
      text1: '소스 코드에서 컴파일하는 경우 실행 가능한 파일 경로는 다음과 같습니다:',
      text2: '동기화가 완료되면 Control + C를 사용하여 프로그램을 닫습니다. target 및 best를 비교하여 동기화가 완료되었는지 확인할 수 있습니다. target 및 best의 차이가 크지 않을 때(예: 100 이내) 동기화가 완료되었다고 볼 수 있습니다.',
      text3: '매개 변수 설명:',
      text4: '--base-path: 블록체인이 데이터를 저장하는 디렉터리를 지정합니다. 지정하지 않으면 기본 경로가 사용됩니다. 디렉터리가 없으면 자동으로 생성됩니다. 이 디렉터리에 이미 블록체인 데이터가 포함되어 있으면 오류가 발생합니다. 이 경우 다른 디렉터리를 선택하거나이 디렉터리의 내용을 지우십시오.',
      text5: '--pruning=archive: 아카이브 모드로 블록체인을 시작합니다',
      text6: '--bootnodes: 부트 노드 주소를 지정합니다.'
    },
    procedure5: {
      title1: '검증자로서 노드 실행',
      text1: '단계 3에서 노드 데이터 동기화를 완료한 후 동기화 명령을 닫습니다. 그런 다음 노드를 검증자로 실행합니다:',
      text2: '소스 코드에서 컴파일하는 경우 실행 가능한 파일 경로는 다음과 같습니다:',
      text3: '여기서 --name YourNodeName은 노드의 이름을 설정합니다. 노드에 고유하고 쉽게 인식할 수 있는 이름을 지정할 수 있습니다. 다른 사람들은 네트워크에서 해당 이름을 볼 수 있습니다.',
      text4: 'rotateKey 생성',
      text5: '검증자 노드 명령이 실행되고 있는 기기에서 다음 명령을 실행합니다'
    },
    procedure6: {
      title1: '본드 금액 설정',
      text1: '열기',
      text2: '귀하의 잔액이 표시됩니다 :',
      text3: '네트워크 > 스테이킹 > 계정 작업 > 스태시 계정으로 이동합니다',
      text4: '본드 금액을 설정합니다 (본드 된 금액 이외에도 트랜잭션을 보내기 위해 충분한 잔액이 있는지 확인하십시오)',
      text5: '설명:',
      text6: '스태시 계정 : 귀하의 자금 계정입니다. 여기서 45 DBC를 본드합니다. 계정에는 이 금액 이상의 잔액이 있는지 확인하십시오.',
      text7: '컨트롤러 계정 : 이 계정에도 작은 양의 DBC가 필요합니다. 검증자를 시작하고 중지하는 트랜잭션을 보내기 위해서입니다.',
      text8: '본드된 값 : 본드/스테이크 할 DBC의 양입니다. 계정의 모든 잔액을 본드할 필요는 없으며, 나중에 본드된 금액을 증가시킬 수 있습니다.',
      text9: '결제 대상 : 검증자가 얻은 보상은이 계정으로 보내집니다. 이 계정은 어떤 계정으로도 설정할 수 있습니다. 여기서',
      text10: '스태시 계정 (스테이크 된 금액을 증가) : 이익은 스테시 계정에 지급되고 스테이크됩니다.',
      text11: '스태시 계정 (스테이크 된 금액을 증가시키지 않음) : 이익은 스테시 계정으로 지급되고 스테이크되지 않습니다.'
    }
  },
  voteReward: {
    title: 'POS 노드에 투표하여 보상 받기',
    text: '각 초슈퍼 노드는 매일 약 6700 DBC의 보상을 받습니다. 투표 노드가 초슈퍼 노드로 선정되면 투표자도 보상을 받을 수 있습니다. 투표자의 보상액은 (1-투표자가 설정한 수수료 비율) * 내 투표 금액 / 총 투표 금액 * 27.25 만으로 계산됩니다. 예를 들어, 내가 투표 한 초슈퍼 노드의 총 투표 금액이 2000 만 DBC이고, 내가 100 만 DBC로 투표하고 노드의 수수료 비율이 10 % 인 경우, 내 매일 보상은 90 % * 100 만 / 2000 만 * 6700 = 301 DBC입니다. 대략적으로, 1 년에 약 11 만 DBC의 보상을 받을 수 있습니다.',
    seedetails: '자세히보기',
    cont: {
      title: 'DBC 후보자가 되다'
    },
    procedure1: {
      title1: '자금 계정 생성 (이미 있는 경우 건너 뛰기)',
      text1: '참고 :',
      text2: '계정 생성 방법'
    },
    procedure2: {
      title1: '일부 DBC 획득',
      text1: '열기',
      text2: '귀하의 잔액이 표시됩니다 :'
    },
    procedure3: {
      title1: '채권 금액 설정',
      text1: '네트워크> 스테이킹> 계정 작업> 스태시 계정으로 이동하십시오.',
      text2: '팝업에서 스태시 계정, 컨트롤러 계정을 선택하고 채권 금액을 입력 한 다음 다음을 클릭하십시오.',
    }
  },
  gpuMiner:{
    title: 'GPU 마이너가 되어 보상을 받으십시오',
    text: 'DBC 생태계에서는 마이너가 매우 중요한 역할을 합니다. 마이너는 GPU 서버를 제공하며, 이는 DBC 네트워크의 기반이 됩니다. 현재 매일 109만 개의 DBC 보상이 GPU 공급 업체에게 지급됩니다. 마이너는 보상 뿐만 아니라 임대 수익도 받습니다. 5,000개 미만의 GPU의 경우 임대 수익은 70%입니다. 5,000개 이상 1만 개 미만의 GPU의 경우 사용자의 임대 수익은 30%입니다. 1만 개 이상의 GPU의 경우 사용자의 임대 수익은 없지만, 기계가 임대될 때마다 보상 수익이 30% 증가합니다. 각 GPU에는 10만 개의 DBC 또는 800 USD에 해당하는 DBC의 최소값이 필요합니다.',
    seedetails: '자세한 규정 보기',
    cont: {
      title: 'GPU 기계의 월 수익 계산 공식',
      text: '기계의 월 수익 계산 공식 : 기계 해시 파워 / 총 네트워크 해시 파워 * 1,095,890 * DBC 가격 * 지리적 계수 * 대형 모델 계수 * 1.3 (임대된 해시 파워 30% 증가) * 30 + 기계 해시 파워 * 지리적 계수 * 대형 모델 계수 * 0.508 * 70%',
      text1: '기계 모델 :',
      text2: '그래픽 카드 수 :',
      text3: '메모리 수 :',
      text4: '지리적 영역 :',
      text5: '선택해주세요',
      text6: '현재 월 수익은 :',
      text7: 'USD 동등 DBC = ',
      text8: '체인 상 보상 수익 :',
      text9: 'DBC (동등',
      text10: 'USD 현재 DBC 가격 :',
      text11: 'USD) + 임대 수익 :',
      text12: 'USD 동등 DBC'
    },
    country: {
      country1: '중국',
      country2: '북미',
      country3: '남미',
      country4: '유럽',
      country5: '중동',
      country6: '한국',
      country7: '일본',
      country8: '대만',
      country9: '홍콩과 마카오',
      country10: '말레이시아',
      country11: '인도네시아',
      country12: '싱가포르',
      country13: '베트남'
    }
  },
  applyCouncil: {
    title: '이사회 회원으로 신청하기',
    text: 'DBC 이사회는 DBC DAO 조직으로 현재 DBC 국고에 80억 이상의 DBC 토큰을 관리하고 있으며 매월 425만 DBC가 국고에 입금되어 2036년까지 지속됩니다. 이사회는 21명의 구성원으로 이루어져 있으며 4개월마다 선거가 개최됩니다. 상위 21명의 후보가 선출됩니다. ',
    text1: '이사회원으로서 국고 자금 제안에 투표할 수 있습니다. 모든 제안은 최소한 21명 이사회원 중 13명의 승인이 필요합니다. 투표수가 13명을 초과하지 않는 경우, 주임 이사는 투표하지 않은 이사를 대표하여 투표할 수 있습니다.',
    text2: '이사회원으로서, 커뮤니티 및 다른 이사회원으로부터 신뢰를 얻을 가능성이 높습니다. 이사회원이 국고 자금 제안을 제출하는 경우, 승인될 가능성이 높아집니다.',
    text3: '어떤 커뮤니티 구성원도 자금 신청 제안을 시작할 수 있습니다. 그러나 제안이 투표 과정에 들어가려면 21명의 이사회원 중 한 명으로부터 제출되어야 합니다.',
    seedetails1: '이사회 회의 토론 그룹',
    seedetails2: '이사회 정보 보기',
    cont: {
      title: '이사회 선거 과정'
    },
    procedure1: {
      title1: 'Polkadot 기반의 DBC 메인넷 지갑',
      text1: '먼저, Polkadot 기반의 DBC 메인넷 지갑이 필요합니다. 지갑을 만든 후 DBC 메인넷 주소를 엽니다.',
      text2: '"거버넌스" 섹션을 선택합니다.',
      text3: '드롭다운 메뉴에서 "의회"를 선택합니다.',
      text3_1: '내부에는 현재 의회 선거 상태에 대한 개요가 표시됩니다.',
      text4: '"좌석" - 현재 선출된 이사회 구성원입니다.',
      text5: '"수상자" - 임시로 제외된 후보자(준우승자)입니다.',
      text6: '"후보자" - 제출되었지만 일시적으로 상위 20에 들지 못한 지원자입니다.',
      text7: '페이지 오른쪽 상단에는 "투표" 및 "후보자 제출" 버튼이 있습니다.',
      text8: '이사회 회원으로 선출하려면 "후보자 제출"로 이동하십시오. 투표만 하려면 "투표"로 이동하십시오.'
    },
    procedure2: {
      title1: '"투표"를 살펴보겠습니다.',
      text1: '입장하면 계정 잔액이 표시됩니다.',
      text2: '원하는 후보를 선택하고 "투표 값" 필드에 투표 수를 입력하십시오.',
      text3: '1 DBC는 1 표와 같습니다. 1 DBC 미만의 투표는 성공하지 않습니다.',
      text4: '확인 후 서명하고 제출하려면 확인용 비밀번호를 입력하십시오.'
    }
  },
  councilVoting: {
    title: '이사회 후보에게 투표하기',
    text: 'DBC 이사회는 DBC 국고를 관리하는 DAO 조직입니다. 이사회 구성원은 DBC 생태계의 발전에 매우 중요한 역할을 합니다. 모든 DBC 보유자는 이사회 투표에 주의를 기울이고, 자신이 지지하는 이사회 후보에게 신성한 한 표를 투표해야 합니다. 총 투표 수 상위 21위에 랭크된 후보만이 이사회로 선출됩니다.',
    seedetails1: '이사회 회의 토론 그룹',
    seedetails2: '이사회 정보 보기',
    cont: {
      title: '어떻게 투표합니까?'
    },
    procedure1: {
      title1: '이사회 투표 페이지 열기',
      text1: '이사회 투표 페이지를 엽니다.',
      text2: '투표를 클릭하십시오 (먼저 지갑에 로그인하십시오)',
      text3: '투표값 설정',
      text4: '투표값을 설정한 후, 이사회 후보를 클릭하면 후보가 오른쪽 상자에 배치됩니다. 여러 후보를 선택하는 경우 투표는 공정하게 분배됩니다. 마지막으로 투표를 클릭하십시오. 참고: 투표 후, 투표 동전은 스테이크되어 전송할 수 없게 됩니다. 전송 작업을 수행하려면 언스테이크가 필요합니다. 새 이사회 선거 종료 전날 투표하는 것이 좋습니다. 이 경우 코인 스테이킹 시간이 가장 짧습니다.',
      text5: '아래 그림의 빨간 박스는 새 이사회 선거 종료까지 남은 시간을 보여줍니다.',
      text6: '투표 취소',
      text7: '투표 동전을 언스테이크합니다. 언제든지 투표를 취소할 수 있습니다. "모두 취소" 버튼을 클릭하면 스테이크가 취소됩니다. 그러나 투표를 취소한 후에는 투표가 취소됩니다. 새 이사회 선거 종료 후에 투표를 취소하는 것이 좋습니다.'
    }
  },
  referenda: {
    title: '공개 투표에 참여하기',
    text: 'DBC 블록체인 네트워크의 모든 기술 업그레이드 및 매개 변수 변경은 공개 투표를 통해 승인해야 합니다. 공개 투표는 DBC 커뮤니티 거버넌스의 중요한 부분입니다. 모든 DBC 소지자는 DBC의 기술 및 제품 개발을 추적하고 공개 투표에 참여하며 때로는 공개 투표를 위한 제안을 시작하는 것이 장려됩니다.',
    seedetails: '공공 제안 보기',
    cont: {
      title: 'DBC 메인넷 투표 참여 방법'
    },
    procedure1: {
      title1: '【건립】-【민주적 권리】인터페이스 소개',
      text1: '제안 — 대중에 의해 시작된 제안. 각 초기화 기간의 끝에서 (현재 매 28일마다), 가장 많은 지원을 받은 제안 (총 dbc 금액으로 표현된 지원)이 공개 투표로 전환됩니다.',
      text2: '외부 — 이사회에서의 제안.',
      text3: '초기화 기간 — 새로운 공개 투표가 시작되는 기간으로, 현재 28일로 설정되어 있습니다.'
    }
  },
  learnDBC: {
    title1: 'What is',
    title2: 'DeepBrain Chain?',
    "text1": "DeepBrain Chain 2.0은 AI 블록체인으로, AI 개발자가 DBC를 기반으로 탈중앙화된 AI 제품을 개발할 수 있습니다. AI 모델은 탈중앙화되어 실행되며, 연구 개발 시간을 90% 단축할 수 있습니다.",
    text2: '2017년에 설립된 DeepBrain Chain Foundation과 DeepBrain Chain Council은 DBC의 개발을 공동으로 촉진합니다.',
    text3: "DBC의 GPU 컴퓨팅 메인넷은 2021년 11월 22일에 정식으로 출시되었으며, DBC AI 블록체인의 테스트넷은 2024년 8월에 출시될 예정입니다.",
    text4: 'DBC는 블록체인 보상 메커니즘을 통해 전 세계의 GPU 제공자들을 모아 DBC 네트워크에 GPU 서버를 제공합니다. 그런 다음 DBC의 분산 네트워크 기술을 통해 AI 개발자는 GPU를 사용할 수 있습니다. AI 개발자는 자신의 토큰을 발행하여 GPU 제공자에게 보상하여 많은 GPU 제공자를 유치할 수 있습니다',
    text5: '생태 어플리케이션이 많을수록, 생태 어플리케이션 자체의 GPU 수요가 커집니다. 매일 사용되는 DBC 거래가 많을수록 파괴되는 DBC도 많아지며, DBC의 가치도 커집니다.',
    text6: '예를 들어 클라우드 카페 어플리케이션을 들어보겠습니다: 클라우드 카페 사용자는 GPU를 사용하기 위해 거래 시장에서 동전을 구매해야 합니다. 추가 GPU를 사용할 때마다 거래 시장에서 구매한 동전의 30%가 파괴됩니다. 만약 1000개의 클라우드 카페가 있다면, 각 카페마다 100대의 기기가 있고, 매 기기가 하루에 10시간 사용되며, 1시간당 0.1달러를 지불합니다. 이 중 30%가 파괴됩니다. 매월 90만 달러의 동전이 파괴됩니다. 0.002 USDT의 동전 가격으로, 한 달에 4억 이상의 동전을 파괴해야 합니다. 동시에 1000개의 클라우드 카페를 지원하기 위해서는 7만 대의 기기가 필요하며, 추가로 70억 개의 동전이 스테이크되어야 합니다.'
  },
  whatisGPU: {
    title1: 'What is',
    title2: 'GPU？',
    text1: 'GPU는 그래픽 및 비디오 처리와 관련된 작업에 특화된 전용 컴퓨팅 장치로, CPU(중앙 처리 장치)와는 달리 대규모 데이터의 병렬 처리를 위해 특별히 설계되었습니다.',
    block1: {
      title: '고도의 병렬 성능',
      text: 'GPU는 수백 개에서 수천 개의 작은 코어로 구성되어 있어 대량의 데이터를 동시에 처리할 수 있습니다. 예를 들어, 3D 그래픽을 렌더링할 때 각 코어는 독립적으로 픽셀이나 정점을 처리하여 처리 속도를 크게 향상시킬 수 있습니다.'
    },
    block2: {
      title: '그래픽 최적화',
      text: 'GPU는 원래 그래픽 렌더링을 가속화하기 위해 설계되었으며, 텍스처 매핑 및 조명 계산과 같은 이미지 및 비디오 관련 작업을 효율적으로 처리할 수 있습니다.'
    },
    block3: {
      title: '다양한 응용 분야',
      text: 'GPU는 원래 게임 및 전문 그래픽 디자인을 위해 설계되었지만, 현재는 인공 지능 및 기계 학습을 비롯한 많은 다른 분야에서도 중요한 역할을 하고 있습니다.'
    },
    title3: '게임 및 인공 지능',
    title4: '왜 GPU가 필요한가요?',
    text2: 'GPU의 고도의 병렬 처리 능력은 그래픽 집약적 작업 및 대규모 데이터 처리 작업을 우수하게 처리할 수 있으므로, 게임 및 인공 지능 분야에서 필수적입니다.',
    text3: '현재 GPU 칩 리더인 NVIDIA의 시가 총액은 1조 달러를 넘어섰으며, CPU 칩 리더 인텔의 6배입니다. 이는 GPU의 수요가 CPU의 수요를 크게 초과한다는 것을 보여줍니다.',
    block4: {
      title: '게임',
      text: '게임 및 현대 게임에는 복잡한 3D 그래픽 및 물리 시뮬레이션이 포함되어 있습니다. 이러한 작업은 대규모 병렬 처리가 필요하기 때문에 강력한 그래픽 처리 능력을 가진 GPU가 매우 적합합니다. GPU를 사용하면 더 부드러운 게임 경험과 더 높은 그래픽 품질을 얻을 수 있습니다.'
    },
    block5: {
      title: '인공 지능 및 기계 학습',
      text: '인공 지능 분야, 특히 딥 러닝에서는 대규모 데이터 처리 및 복잡한 수학적 계산이 필요합니다. 이러한 계산 작업은 보통 병렬화될 수 있으므로 GPU의 고도의 병렬 성능에 매우 적합합니다. GPU를 사용하면 모델 훈련 및 추론 속도를 크게 높일 수 있습니다.'
    }
  },
  historyDBC: {
    title: 'DeepBrainChain 발전 역사',
    title1: '2017년',
    text1_1: 'DeepBrainChain 프로젝트 시작, 목표, 비전 및 기술 아키텍처 방향 설정',
    text1_2: '자금 조달 완료',
    title2: '2018년',
    text2_1: 'Huobi 거래소에 DBC 토큰 상장',
    text2_2: 'DBC 컴퓨팅 파워 네트워크 시작 및 GitHub에서 코드 공개',
    title3: '2020년',
    text3_1: 'DBChain 글로벌 AI 개발자 사용자가 1만 명을 넘어 전 세계 500여개 이상의 AI 관련 대학 및 연구소에 서비스 제공',
    title4: '2021년',
    text4_1: 'DeepBrainChain 노드 메인넷 공식 시작',
    text4_2: 'DeepBrainChain 메인넷 브라우저 론칭',
    text4_3: 'DeepBrainChain을 기반으로 한 클라우드 플랫폼이 50개 이상',
    text4_4: 'DeepBrainChain GPU 컴퓨팅 파워 메인넷 공개 테스트 시작',
    text4_5: '테스트 네트워크의 GPU 컴퓨팅 파워 총량이 1200장을 넘고, 임대율은 98% 이상',
    text4_6: 'DeepBrainChain GPU 컴퓨팅 파워 메인넷 공식 시작',
    title5: '2022년',
    text5_1: 'Galaxian 대회 GPU 수가 1500개로 도달',
    text5_2: 'dbc를 기반으로 한 haibaogpu 클라우드 플랫폼 시작, AI 사용자에게 GPU 서비스 제공',
    text5_3: '한국의 3개 풀 - DBC KOREA HILLSTONE, DBC KOREA Ai-Factory 및 Gines Korea Center-1이 DBC 네트워크에 가입',
    text5_4: 'dbc를 기반으로 한 한국의 hycons 클라우드 플랫폼 시작, AI 및 클라우드 게임 클라이언트에게 GPU 서비스 제공',
    text5_5: 'dbc를 기반으로 한 DeepLink 클라우드 게임 플랫폼 시작, 원격 제어 도구로서의 클라우드 게임 플랫폼으로 기능',
    title6: '2023년',
    text6_1: 'Huawei, DeepBrainChain 및 Polygon이 협력하여 메타버스 및 Web3 얼라이언스를 설립',
    text6_2: 'DeepBrainChain과 DeepLink 기술을 활용한 세계 최초의 ZestCloud 클라우드 사이버카페가 한국 서울에서 시범 운영 시작',
    text6_3: 'DeepBrainChain과 DeepLink 기술을 활용한 세계 최초의 클라우드 게임 머신 TIKEREN이 일본 도쿄에서 발표되고 공동 발표가 진행되었습니다',
    title7: '2024년',
    text7_1: 'Q1',
    text7_2: '1. GPU 단기 임대 모드 개발',
    text7_3: '2. 본넷의 새로운 기능 출시',
    text7_4: 'Q2',
    text7_5: '1. 스마트 계약 기능 지원 개발',
    text7_6: '2. GPU 단기 임대 모드 강화',
    text7_7: '3. 게임Fi 게임을 클라우드 게임Fi로 전환하는 지원',
    text7_8: 'Q3',
    text7_9: '1. DBC를 기반으로 하는 분산형 AIGC 프로젝트의 스마트 계약 개발 지원',
    text7_10: '2. DBC GPU를 사용한 분산형 AIGC 프로젝트의 채굴 지원',
    text7_11: '3. 스마트 계약 기능 개발 테스트 완료',
    text7_12: 'Q4',
    text7_13: '1. DBC GPU를 사용한 3A GameFi 채굴 지원',
    text7_14: '2. DBC 생태계 내에서 DBC 토큰과의 토큰 거래를 지원하는 DBCSwap 기능 개발',
  },
  introDBC: {
    title: 'DBC DAO 자치 소개',
    text1: '2021년에 DBC 메인넷이 시작되면서, 현재는 DBC의 발전을 공동으로 촉진하는 두 개의 조직이 있습니다. 그것은 DBC 재단과 DBC 이사회입니다.',
    text2: 'DBC 재단은 DBC의 창시 조직으로, 주로 DBC의 기술 개발과 전 세계적인 홍보를 담당합니다. DeepBrainChain Foundation 및 DeepBrainChain Board는 완전히 독립적인 두 조직으로, 어떠한 종속 관계도 없으며, DeepBrainChain 생태계를 공동으로 서비스하고 DeepBrainChain의 발전을 촉진합니다.',
    cont2: {
      block1: {
        title: 'DBC 이사회',
        text1: '커뮤니티 선출의 DAO 조직으로, 국고 자금을 관리하고 DBC 생태 애플리케이션을 지원하며 DBC 커뮤니티를 발전시키며 DBC 거버넌스를 수행합니다.',
        text2: '현재는 DBC 국고 총 8억 개 이상의 DBC 토큰을 관리하고 있으며, 매월 425만 개의 DBC가 국고에 들어가며, 이는 2036년까지 계속됩니다.',
        text3: '이사회는 총 21명의 회원으로 구성되어 있으며, 4개월마다 한 번씩 선출됩니다. 상위 21명의 후보가 선출되며.',
      },
      block2: {
        title: '이사회 회원으로 참여',
        text1: '이사회 회원이 되면 국고 자금 신청 제안에 투표할 수 있으며, 제안은 21명의 이사 중 13명의 투표를 받아야합니다. 투표가 13명 미만이면 사장이 투표하지 않은 이사를 대신하여 투표를 할 수 있습니다.',
        text2: '이사회 회원이 되면 커뮤니티와 다른 이사들의 신뢰를 얻을 수 있으며, 이사 자신이 국고 자금 제안을 신청할 때 통과하기가 더 쉽습니다.',
        text3: '어떤 커뮤니티 회원이든지 자금 신청 제안을 시작할 수 있지만, 제안은 21명 중 한 명의 이사가 투표를 제출해야 투표 절차에 진입할 수 있습니다.',
        btn: '자세한 내용 보기'
      }
    },
    cont3: {
      title: 'DBC 자금 풀 사용 목적',
      text1: '인프라 배치',
      text1_2: '및 지속적인 운영',
      text2: '네트워크 보안 운영',
      text2_1: '모니터링 서비스, 지속적인 감사',
      text3: '생태계 지원',
      text3_1: '및 우호적인 체인과의 협력',
      text4: '마케팅 활동',
      text4_1: '광고, 지불, 협력',
      text5: '커뮤니티 및 외부활동',
      text5_1: '모임, 피자 파티, 해커스페이스',
      text6: '인프라 배치 및 지속적인 운영',
      text6_1: '지갑 및 지갑 통합, 클라이언트 및 클라이언트 업그레이드',
      text7: '거래소 상장',
      text8: '더 보기...'
    },
    cont4: {
      title: '이번 임기의 현 임원 위원회 상위 세 명의 DAO 회원',
      job: '최고 임원',
      reward: '투표:'
    },
    cont5: {
      title: '신임 이사 선거까지 카운트다운',
      text1: '제안',
      text2: '진행 중',
      text3: '승인됨',
      text3_1: '거부됨',
      text4: '신청 금액',
      text5: '신청자:',
      text6: '종료 시간',
      text7: '시간',
      text8: '경과 시간',
      text9: '찬성',
      text10: '반대',
      text11: '일'
    },
    seemore: '더보기'
  },
  aboutTeam: {
    title: '팀 소개',
    lead: 'HE YONG',
    job: '창립자',
    intro : {
      text1: "DeepBrainChain와 DeepLink의 창립자 He Yong, 연속 창업가",
      text2: "AI 14년, 블록체인 및 분산 컴퓨팅 네트워크 기술 개발 경험 7년. 2008년 중국 해양 대학에서 컴퓨터 전공 학사 학위 취득, 2010년 동쪽 중국 정상 대학에서 생물 정보학 석사 및 박사 과정 중퇴",
      text3: "2012년 Jushang Intelligence를 설립하고, 세계 최초의 중국어 AI 음성 어시스턴트 인 Smart 360을 개발했습니다. 3년 동안 소프트웨어 다운로드 사용자 수가 1700만 명을 넘었습니다.",
      text4: "2014년 세계 최초의 AI 스마트 스피커 인 Xiao Zhi Super Speaker를 출시했습니다.",
      text5: "2017년 5월 DeepBrain Chain을 설립하여 현재 수백 개의 AI, 클라우드 게임, 사이버 카페 고객에게 서비스를 제공하고 있습니다.",
      text6: "2020년 DeepLink를 설립하고 DeepLink 프로토콜은 분산형 AI 클라우드 게임 프로토콜로, DeepBrain Chain과 함께 전 세계 사이버 카페 및 AAA 게임 회사에 서비스를 제공합니다."
    },
    application: {
      title: '직책 지원',
      desc: 'DeepBrainChain 재단은 현재 싱가포르, 중국, 미국, 러시아, 한국, 타이완, 일본, 인도, 베트남, 방글라데시, 나이지리아 등 국가에 분포한 50명 이상의 전임 및 파트타임 연구 및 마케팅 직원을 보유하고 있습니다'
    },
    weekly: '최근 16회 DBC 이주보고서',
    see: '자세히 보기',
    seemore: '더보기'
  },
  deployCloud: {
    title: '강력한 GPU 대여 API',
    step1: {
      text1: '체인상 GPU 대여 API',
      text2: 'API 노드 주소: xxxx, 또는 자체 블록체인 API 동기화 노드를 배포할 수 있습니다. 배포 문서:',
      text3: '체인 상 머신 대여',
      text4: '가용성 확인 및 대여',
      text5: '연장',
    },
    step2: {
      text1: '전체 GPU 머신 대여',
      text2: '가상 머신 생성',
      text3: '가상 머신 재시작',
      text4: '가용성 확인 및 대여',
      text5: '연장',
    },
    step3: {
      text1: 'GPU 베어 메탈 머신 대여',
      text2: '머신 생성',
      text3: '머신 재시작',
      text4: '종료',
      text5: 'DeepLink 장치 ID 및 확인 코드 가져오기',
      text6: 'Ubuntu 계정 및 암호 가져오기',
    },
    server: '질문이 있으시면 문의하세요：'
  },
  cloudInternet: {
    title: '클라우드 인터넷 카페 솔루션 제공자로의 전환 방법',
    text: '현재 전 세계적으로 주요 인터넷 카페 분포 국가는 한국 (6,000), 중국 (40,000), 브라질 (10,000), 인도네시아 (20,000), 필리핀 (20,000), 베트남 (15,000), 터키 (5,000) 등입니다. 또한, 인도, 멕시코, 중동, 남아메리카, 아프리카, 유럽 등의 국가도 있습니다. 전 세계적으로 15 만 개 이상의 인터넷 카페가 있습니다. 그러나 인터넷 카페 산업은 20 년의 역사를 가지고 전통 산업이 되었습니다. 회수 기간은 3-4 년으로 증가하고 다양한 위험에 직면하고 있습니다. 예를 들어 COVID-19 팬데믹으로 많은 인터넷 카페가 폐쇄되었습니다. 따라서 인터넷 카페 산업은 회수 기간을 단축하고 위험을 감소시키며 운영 효율을 향상시킬 새로운 솔루션이 필요합니다. 이렇게하여 클라우드 인터넷 카페 솔루션이 탄생합니다.',
    cont2: {
      text1: '무엇',
      text2: '클라우드 PC방 솔루션?',
      text3: '클라우드 사이버 카페 솔루션은 초저지연 렌더링 기술을 갖춘 새로운 유형의 사이버 카페 서비스 모델입니다. 클라우드 사이버 카페에서는 게임 및 응용 프로그램이 로컬 컴퓨터에 직접 설치되지 않고 클라우드 GPU 서버에 호스팅됩니다. 사용자는 고성능 로컬 하드웨어가 필요 없이 클라우드 서비스를 통해 게임에 액세스하고 플레이할 수 있습니다.',
      block1: {
        text1: '로컬 하드웨어 요구 사항 줄이기',
        text2: '게임 및 응용 프로그램이 클라우드 GPU 서버에서 실행되므로 로컬 컴퓨터에 고성능 GPU 또는 대용량 저장 공간이 필요하지 않습니다. 이는 사이버 카페 하드웨어 투자 및 유지 관리 비용을 줄입니다.'
      },
      block2: {
        text1: '최신 게임 즉시 액세스',
        text2: '사용자는 다운로드 및 설치를 기다릴 필요없이 최신 게임에 즉시 액세스할 수 있습니다. 모든 게임 업데이트 및 유지 관리는 클라우드에서 이루어지므로 사용자에게 편리하고 효율적인 경험을 제공합니다.'
      },
      block3: {
        text1: '고성능 게임 경험',
        text2: '로컬 모니터는 미니 PC (약 100 달러)로 구성되어 있으며 클라우드 사이버 카페에서도 고성능 게임 경험을 제공할 수 있습니다. 이는 게임 계산이 원격 고성능 GPU 서버에서 이루어지기 때문입니다.'
      },
      block4: {
        text1: '공간 및 에너지 절약',
        text2: '낮은 구성의 로컬 컴퓨터는 더 적은 공간을 차지하며 더 적은 전력을 소비하여 사이버 카페 운영 비용을 절감하는 데 도움이 됩니다.'
      },
      block5: {
        text1: '쉬운 관리 및 유지 관리',
        text2: '사이버 카페 관리자는 모든 콘텐츠가 클라우드에서 중앙 집중식으로 관리되기 때문에 소프트웨어 및 게임을보다 쉽게 관리할 수 있습니다. 이로 인해 로컬 유지 관리 작업 및 잠재적 인 기술 문제가 줄어듭니다.'
      }
    },
    cont3: {
      text1: '한국의 Glowsteam 클라우드 사이버 카페',
      text2: '클라우드 사이버 카페의 실제 운영 환경'
    },
    cont4: {
      text1: '클라우드 사이버 카페 솔루션은 사이버 카페 투자자를 돕는 데 도움이 될 수 있습니다',
      text2: '순이익을 3배로 늘리고 투자 회수 기간을 40% 단축합니다',
      text3: '비교 데이터를 다운로드하려면 클릭하세요'
    },  
    cont5: {
      text1: '클라우드 사이버 카페 솔루션 제공업체의 수익은 어떻게 발생하나요?',
      text2: '수익이 사이버 카페 기기 당 수입의 12%로 계산되면, 총 1000 개의 사이버 카페에 10 만 개의 기기를 서비스하고, 각 기기가 하루 평균 10 시간 사용되며, 기기 당 시간당 평균 비용이 0.8 달러 인 경우 12%는 약 0.1 달러입니다.',
      text3: '클라우드 사이버 카페 솔루션 제공업체의 연간 수익은',
      text4: '100,000 * 10 * 0.1 * 365 = 3,650 만 달러',
      text5: '클라우드 사이버 카페 솔루션 제공업체가 되는 방법',
      text6: 'DeepBrainChain 및 DeepLink 기술을 기반으로 한 한국의 HYCONS가 완전한 클라우드 사이버 카페 솔루션을 개발했습니다. DeepBrainChain은 GPU 컴퓨팅 파워를 제공하고, DeepLink는 초저 지연 렌더링 기술을 제공하며, HYCONS는 전체 솔루션을 제공합니다. 유사하게, DeepBrainChain은 엔진을 제공하고, DeepLink는 자율 주행 시스템을 제공하며, HYCONS는 완전한 차량 솔루션을 제공합니다. 다양한 국가의 로컬 솔루션 제공 업체는 로컬 결제 시스템을 HYCONS에 통합하기만 하면 현지 사이버 카페에 서비스를 제공할 수 있으며, 솔루션 제공 업체는 자체 브랜드를 설정할 수 있습니다. HYCONS는 브랜드를 노출하지 않고 순수한 기술 서비스 제공자로만 기능합니다.',
      text7: '자세한 정보는 문의하십시오 :',
      text8: "service{'@'}deepbrainchain.org"
    },
    cont6: {
      title: '클라우드 사이버 카페 관련 자료 다운로드',
      text1: 'HYCONS 소개',
      text2: '클라우드 사이버 카페 솔루션 수익 분석',
      text3: '클라우드 사이버 카페 데이터 센터 아키텍처 다이어그램',
      text4: '클라우드 사이버 카페 솔루션 테스트 및 설치 매뉴얼',
      text5: 'DeepLink 프로토콜 소개'
    }
  },
  gameAgents: {
    title: '클라우드 게임 콘솔 대리점이 되는 방법',
    text: '클라우드 게임 콘솔이란 무엇입니까? 전통적인 게임 콘솔과 비교하여 클라우드 게임 콘솔은 높은 FPS 새로 고침률을 가지고 있어 더 부드러운 게임 경험을 제공합니다. HKC 그룹의 TIKEREN 클라우드 게임 콘솔은 DeepBrainChain + DeepLink 기술을 기반으로 개발되어 실시간으로 원격 GPU 고성능 컴퓨팅에 연결되어 게임 렌더링 및 계산을 수행합니다.',
    title1: 'TIKEREN 콘솔 프레젠테이션',
    table: {
      th: {
        text1: '정렬',
        text2: '품목',
        text3: '27인치',
        text4: '31.5인치'
      },
      td1: {
        text1: 'Mode Type',
        text2: 'MA27H13QC1',
        text3: 'MA27H13QC1'
      },
      td2: {
        text1: 'Color Category',
        text2: 'Black',
        text3: 'Black'
      },
      td3: {
        text1: 'Display Size',
        text2: '27인치',
        text3: '31.5인치'
      },
      td4: {
        text1: 'Aspect Ratio',
        text2: 'Wide (16:9)',
        text3: 'Wide (16:9)'
      },
      td5: {
        text1: 'Panel Type',
        text2: 'IPS',
        text3: 'VA'
      },
      td6: {
        text1: 'Panel Resolution',
        text2: 'QHD 2560*1440',
        text3: 'QHD 2560*1440'
      },
      td7: {
        text1: 'Panel Refresh Rate',
        text2: '180Hz (Max)',
        text3: '170Hz(Max)'
      },
      td8: {
        text1: 'Responese Time',
        text2: '1mS(MPRT).GTG 1ms',
        text3: '1ms(MPRT).GTG 1ms'
      },
      td9: {
        text1: 'Color Gamut',
        text2: 'DCIP3 90%',
        text3: 'DCIP3 90%'
      },
      td10: {
        text1: 'Mainboard Solution',
        text2: 'Intel® N100 Chipset',
        text3: 'Intel® N100 Chipset'
      },
      td11: {
        text1: 'CPU/Cooler',
        text2: 'Intel N100 3.4GHz',
        text3: 'Intel N100 3.4GHz'
      },
      td12: {
        text1: 'Memory',
        text2: 'DDR4 8GB(Option Max 32G)',
        text3: 'DDR4 8GB(Option Max 32G)'
      },
      td13: {
        text1: 'Hard Disk',
        text2: 'SSD 128G(Option Max 1T)',
        text3: 'SSD 128G(Option Max 1T)'
      },
      td14: {
        text1: 'OS',
        text2: 'Windows 10/11(0ption)',
        text3: 'Windows 10/11(0ption)'
      },
      td15: {
        text1: 'GPU',
        text2: 'Intel UHD Graphics',
        text3: 'Intel UHD Graphics'
      },
      td16: {
        text1: 'Rear Interface',
        text2: '1*DC Power Connector，1*RJ45(2.5G)，4* USB 3.0，1*Audio jacks',
        text3: '1*DC Power Connector，1*HDMIN，1*HDMI OUT，1*RJ45(2.5G)，4* USB 3.0,1*Audio iacks'
      },
      td17: {
        text1: 'Side Interface',
        text2: 'USB 2.0x2，Power Button x1',
        text3: 'USB 2.0x2，Power Button x1'
      },
      td18: {
        text1: 'Wi-Fi',
        text2: '2.4G~5GHz(Wi-Fi5),BT(4.2)',
        text3: '2.4G~5GHz(Wi-Fi5),BT(4.2)'
      },
      td19: {
        text1: 'Audio',
        text2: 'Built-in Speakers*2',
        text3: 'Built-in Speakers*2'
      },
      td20: {
        text1: 'Base/Stand',
        text2: 'HAS:VESA',
        text3: 'HAS:VESA'
      },
      td21: {
        text1: 'Pivot',
        text2: '위, 아래, 왼쪽, 오른쪽, 90° 회전, 높이 조절',
        text3: '위, 아래, 왼쪽, 오른쪽, 90° 회전, 높이 조절'
      },
      td22: {
        text1: 'Power',
        text2: '90W',
        text3: '90W'
      },
      td23: {
        text1: 'Game Screenlmprovement',
        text2: 'Free Sync, G-Sync',
        text3: 'Free Sync, G-Sync'
      }
    },
    text1: '이메일로 연락주세요'
  },
  convertGame: {
    title: 'AAA 게임을 클라우드 게임으로 변환하는 방법',
    text: 'DeepLink 프로토콜은 분산형 초저 지연 클라우드 게임 렌더링 프로토콜이며, DeepLink 프로토콜을 기반으로 클라우드 게임 관련 제품을 개발할 수 있는 모든 응용 프로그램에서 클라우드 컴퓨팅 파워는 DeepBrainChain에서 제공됩니다.',
    text1: 'DeepLink 프로토콜 이해',
    step: {
      title1: '게임을 DeepLink 프로토콜에 통합하기',
      text1: "service{'@'}deeplink.cloud에 문의하십시오",
      title2: 'DeepLink는 자동으로 DeepBrainChain GPU 서버를 호출합니다',
      text2: '게임을 클라우드에서 실행'
    }
  },
  applyForFunding: {
    title: '이사회 국고 자금 신청 방법',
    text1: '자금을 신청하기 전에 이사회 자금 신청 Telegram 토론 그룹에 참여할 수 있습니다.',
    text2: 'Telegram 토론 그룹',
    cont: {
      title: 'DBC 자금 신청 프로세스',
      text: '신청자가 국고에서 자금을 요청할 때는 제안액의 5%를 예약해야 합니다. 국고 제안이 승인되면 예약된 자금이 환불됩니다. 국고 제안이 거부되면 예약된 자금이 처벌됩니다. 사용자는 재정 제안을 제출한 후 제안을 철회할 수 없습니다.',
    },
    procedure: {
      text: '이사회 국고 자금 신청 절차:',
      title1: '안 제출',
      text1: '제안서 템플릿을 기반으로 초안을 준비하고 제안서 템플릿을 참조하십시오',
      text2: '영어:',
      text3: '중국어:',
      title2: '체인 외에서 제안 논의',
      text4: '체인에 제안을 제출하기 전에 제안자는 어떤 포럼에서든지 이사회 및 커뮤니티 구성원과 제안을 논의할 수 있습니다. WeChat, 텔레그램 그룹 등을 통해 여러 의견을 통합하고 제안을 계속 개선하십시오. 현재 DBC는 Reddit (https://www.reddit.com/r/DBC_Council/)에서 DBC_Council이라는 하위 레딧 커뮤니티를 설정했습니다. 중국 본토에서는 VPN이 필요합니다. 커뮤니티는 또한 제안 논의를위한 섹션 [제안_초안_논의]을 설정했습니다. 제안자는이 커뮤니티에 초안을 게시하고 논의를 위해 플레어 [제안_초안_논의]를 추가 할 수 있습니다.',
      title3: '체인 상 제출',
      text3_1: 'DBC 메인넷의 거버넌스-국고 페이지 (https://www.dbcwallet.io/#/treasury)에서 제안을 체인 상에 제출하고 요청된 DBC 금액을 입력하십시오. 그런 다음 시스템은 DBC의 5 %를 담보로 자동으로 스테이크합니다.',
      text3_2: '참고! 제안이 승인되면 담보가 반환됩니다. 제안이 거부되면 담보가 반환되지 않습니다!',
      text3_3: '따라서 제안 준비에 주의를 기울이고 상대적으로 완전하고 실행 가능한 제안을 제출하십시오.',
      text3_4: '체인 상 제출과 동시에 제안자는 레딧 커뮤니티 DBC_Council (https://www.reddit.com/r/DBC_Council/)에 제안 내용을 게시하고 플레어 [체인 상 제출된 제안]을 추가해야합니다. 체인 상 제출 후 제안 번호와 블록 높이가 전송됩니다. 제안 게시물에 [번호]를 추가하여 체인 상 및 체인 외 대응을 용이하게합니다.',
      title4: '이사회 제안 및 투표',
      text4_1: '신청자가 신청을 완료하면 https://www.dbcwallet.io/#/treasury에서 신청 내용을 확인할 수 있습니다.',
      text4_2: '이 때, 이사회는 신청에 대한 동의 또는 반대의 동의안을 제안할 수 있습니다. 신청을 수락하는 동의안에는 이사회의 3/5의 동의가 필요하고, 신청을 거부하는 동의안에는 이사회의 1/2의 동의가 필요합니다.',
      text4_3: '주의! 이사회는 의회에서 투표를 위해 국고 (https://www.dbcwallet.io/#/treasury)에 제안을 제출해야합니다 (https://www.dbcwallet.io/#/council/motions).',
      title5: '투표 완료',
      text5_1: '투표 기간이 종료되면 이사회는 동의안을 수동으로 닫아야합니다 (https://www.dbcwallet.io/#/council/motions).',
      text5_2: '투표 후, 승인된 제안은 DBC subreddit의 커뮤니티 관리자에 의해 플레어 [제안_승인됨]에 아카이브되고 거부된 제안은 플레어 [제안_거부됨]으로 분류됩니다.',
      title6: '자금 배포',
      text6_1: '자금은 분할하여 배포되며, 성공한 제안자는 지속적으로 진행 상황을 업데이트해야합니다. 제안 당 각 단계에 필요한 자금을 명시하십시오. 제안의 완료는 커뮤니티 구성원 (이사회 구성원이 아님)이 투표를 통해 감독합니다.',
      text6_2: '특별히 : 자금 수령 주소는 거래소 주소가 아니어야하며 개인 키를 제어하는 주소 여야합니다. 가능하면 DBC 메인 넷에서 생성 된 주소 여야합니다. 그렇지 않으면 승인 후 자금이 지급되지 않습니다.',
      text6_3: '주의 : 승인 된 자금 신청의 지급은 지출 주기 종료 시에 이루어집니다. 지출 주기는 https://www.dbcwallet.io/#/treasury (우측 상단에 지출 주기 카운트 다운이 표시됨)에서 확인할 수 있습니다.'
    },
  },
  createwallet: {
    title: 'DBC월렛',
    text: 'DBC월렛은 DBC 재단에서 유지하는 완전한 기능을 갖춘 월렛으로, 이 월렛을 사용하여 DBC 및 DLC 월렛 주소를 생성하고 POS 투표에 참여하거나 이사회 선거 투표에 참여하거나 투표 등에 참여할 수 있습니다.',
    text1: '웹사이트 방문하기',
    cont2: {
      title: '월렛 주소 생성 방법',
      text1: '사용자 자산 보안을 보호하기 위해 플러그인 형식으로 월렛 생성됩니다',
      step: {
        title1: 'Google Chrome 브라우저 설치하기',
        title2: 'Polkadot 플러그인 검색하기',
        text2: '플러그인 설치하기',
        title3: '시드 문구 저장하기',
        text3: 'Polkadot 플러그인을 열고 월렛을 생성한 다음 모든 체인을 지원하는 옵션을 선택하여 시드 문구를 저장합니다',
        title4: 'www.dbcwallet.io 새로고침하기',
        text4: '내 계정 페이지에는 이미 생성된 월렛이 표시됩니다'
      }
    },
    title1: '월렛 생성 비디오'
  },
  polkaWallet: {
    title: 'PolkaWallet 지갑',
    text: 'Google Play 및 App Store에서 PolkaWallet을 검색하거나 공식 웹 사이트인 PolkaWallet.io를 방문하여 다운로드하십시오.',
    text1: 'PolkaWallet.io에서 다운로드',
    // step: {
    //   title: '创建流程',
    //   text: '为了保护用户持币安全，采用插件的形式创建钱包',
    //   title1: '安装Google Chrome浏览器',
    //   title2: '搜索Polkadot插件',
    //   text2: '安装插件',
    //   title3: '保存种子',
    //   text3: '打开Polkadot插件，点击创建钱包，选择支持所有链，保存种子',
    //   title4: '刷新www.dbcwallet.io',
    //   text4: '我的账户页面会出现已经创建的钱包'
    // },
    step: {
      title: '생성 과정',
      text1: '1. 브라우저에서 polkawallet.io를 엽니다.',
      text2: '2. 파독 공식 웹 사이트에서 APP 지갑을 다운로드합니다.',
      text3: '3. 다운로드 한 후 DBC 메인넷 지갑의 "새로 만들기" 또는 "가져 오기"를 선택합니다.',
      text4: '4. Substrate 계정을 선택합니다.',
      text5: '5. 새 지갑의 경우 키를 적절히 백업하십시오.',
      text6: '6. 이름과 비밀번호를 설정한 다음 주요 인터페이스로 이동할 수 있습니다.',
      text7: '7. 주요 인터페이스에서 왼쪽으로 스와이프하여 DBC를 선택합니다.',
      text8: '8. 아이콘을 클릭하여 메인넷 DBC를 송수신합니다.',
      text9: '9. 수신 인터페이스.',
      text10: '10. 전송 및 이체 인터페이스. 메인넷 지갑에 사용되는 가스는 DBC이므로 송금시에는 소량의 DBC를 예약하십시오.'
    }
  },
  mathWallet: {
    title: 'MathWallet 지갑',
    text: 'Google Play 및 App Store에서 MathWallet을 검색하거나 공식 웹 사이트인 https://mathwallet.org/를 방문하여 다운로드하십시오.',
    text1: 'MathWallet 다운로드',
    step: {
      title: '생성 과정',
      text1: '1. 브라우저에서 mathwallet.org를 입력하세요.',
      text2: '2. 공식 웹 사이트에 들어간 후 다운로드 경로를 선택하세요.',
      text3: '3. 안드로이드의 경우 설치 파일을 직접 다운로드하거나 Google Play Store로 이동하여 다운로드할 수 있습니다.',
      text4: '4. 다운로드가 완료되면 지갑을 열고 수동으로 DBC 메인 네트워크를 선택하세요.',
      text5: '5. DBC 메인 네트워크 지갑은 끝에서 4 페이지에 있습니다.',
      text6: '6. 생성하거나 가져올 수 있습니다.',
      text7: '7. 비밀번호 생성에는 대소문자가 모두 필요합니다.',
      text8: '8. 니모닉 문구를 백업하세요.',
      text9: '9. DBC 아이콘이 표시되면 클릭하여 자산을 받고 보낼 수 있습니다.',
      text10: '10. 전송 인터페이스.',
      text11: '11. 왼쪽으로 스와이프하여 더 많은 지갑을 생성하세요.',
      text12: '12. 오른쪽 하단의 설정에서 언어를 선택할 수 있습니다.',
      text13: '13. 메인 인터페이스 하단 중앙에는 메인 네트워크 브라우저가 표시됩니다.'
    }
  },
  ERC20Wallet: {
    title: 'ERC20 Wallet',
    text1: 'MetaMask',
    text2: 'Imtoken',
    text3: 'TrustWallet',
    text4: 'DBC 계약 주소 검색:',
    text5: '정밀도:',
  },
  ImToken: {
    title: 'ImToken 지갑',
    text: '공식 웹 사이트를 방문하여 다운로드하십시오: token.im',
    text1: 'token.im 다운로드',
    step: {
      title: '생성 프로세스',
      text1: '1. 브라우저에 token.im을 입력하십시오',
      text2: '2. 입력 후 "지금 다운로드"를 클릭하십시오',
      text3: '3. 직접 설치하거나 Google Play Store에서 설치할 수 있습니다',
      text4: '4. 다운로드 후 앱에서 지갑을 생성하십시오',
      text5: '5. 생성 후에는 즉시 키를 백업하고 안전하게 보관하는 것이 좋습니다',
      text6: '6. 지갑의 주요 페이지에서 "+" 기호를 클릭하여 토큰을 추가하십시오',
      text7: '7. 검색 창에 DBC 계약 주소를 입력하십시오: 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. 성공적으로 추가한 후 메인 페이지로 돌아가 DBC 아이콘을 볼 수 있습니다',
      text9: '9. 아이콘을 클릭하여 DBC 수신 및 전송 주소를 볼 수 있습니다',
      text10: '10. 다른 사람에게 보낼 때는 그들의 ERC20 주소를 사용해야 합니다',
    }
  },
  trustWallet: {
    title: 'TrustWallet 지갑',
    text: 'Google Play 및 앱 스토어에서 TrustWallet을 검색하거나 공식 웹 사이트 https://trustwallet.com/ 에 방문하여 다운로드하십시오',
    text1: 'TrustWallet 다운로드',
    step: {
      title: '생성 프로세스',
      text1: '1. 브라우저에 trustwallet.com을 입력하십시오',
      text2: '2. 입력 후에는 세 가지 다운로드 옵션이 표시됩니다',
      text3: '3. 다운로드한 후 새 지갑을 만들어주세요',
      text4: '4. 새 지갑을 즉시 백업하십시오',
      text5: '5. 백업이 완료되면 메인 인터페이스로 이동합니다',
      text6: '6. 메인 인터페이스에서 오른쪽 상단에 겹쳐진 작은 "+" 아이콘을 클릭한 다음 현재 페이지의 오른쪽 상단에있는 "+" 아이콘을 클릭하십시오',
      text7: '7. 토큰을 가져 오는 화면에서 DBC의 계약 주소를 붙여 넣으십시오 : 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text8: '8. 가져 오기가 성공하면 DBC 아이콘이 표시됩니다',
      text9: '9. "받기"를 클릭하여 현재 DBC 지갑 주소를 가져옵니다',
      text10: '10. "보내기"를 클릭하여 전송 인터페이스에 입력하고 트랜잭션을 완료하려면 충분한 ETH가 ERC20 체인에 예약되어 있는지 확인하십시오',
    }
  },
  metaMask: {
    title: 'MetaMask 지갑',
    text: 'Google Play에서 MetaMask를 검색하거나 공식 웹 사이트 https://metamask.io/에서 다운로드하십시오.',
    text1: 'MetaMask 다운로드',
    step: {
      title: '생성 프로세스',
      text1: '1. 브라우저에서 metamask.io를 엽니다',
      text2: '2. Google Play Store에서 설치합니다',
      text3: '3. 다운로드 한 후 지갑을 가져 오거나 만들어 메인 인터페이스로 이동합니다',
      text4: '4. 인터페이스 하단의 "토큰 가져 오기"를 클릭하여 토큰 추가 페이지로 이동합니다',
      text5: '5. "사용자 정의 토큰"을 선택하고 DBC 계약 주소를 입력합니다 : 0x1F735f84b07cc20E9aC471C291a87b5A2428d518',
      text6: '6. 성공적으로 가져 오면 메인 인터페이스에 DBC 아이콘이 표시됩니다',
      text7: '7. 클릭하여 입력하고 DBC를받을 옵션을 볼 수 있으며 보낼 수 있습니다',
      text8: '8. 송금에는 가스로 ETH가 지갑에 있어야합니다'
    }
  },

  whatisAi: {
    title1: '탈중앙화 AI 제품이란?',
    text1: '탈중앙화 AI 제품은 모든 단계가 탈중앙화 방식으로 운영되는 AI 제품을 의미합니다. 제품은 주로 결제 정산과 모델 실행의 두 부분으로 나뉘며, 이 두 부분이 모두 탈중앙화되어야 탈중앙화 AI 제품이라고 할 수 있습니다.',
    title2: '중앙화 AI 제품이란?',
    text2: '중앙화 AI 제품은 모든 단계가 중앙화 기관에 의해 관리되는 제품입니다. 예를 들어, 결제 정산은 중앙화 기관의 서버에서 처리되고, AI 모델도 중앙화 기관이 관리하는 GPU 서버에서 실행됩니다. 즉, 중앙화 기관은 제한을 가하거나 수정할 수 있는 권한을 가집니다.',
    title3: '반 탈중앙화 AI 제품이란?',
    text3: '반 탈중앙화 AI 제품은 일부는 중앙화 기관에 의해 관리되고, 일부는 탈중앙화된 제품입니다. 예를 들어, 결제 정산은 토큰 스마트 계약을 통해 이루어지지만, AI 모델은 중앙화 기관이 관리하는 GPU 서버에서 실행됩니다.',
    title4: '탈중앙화 AI 프로젝트와 반 탈중앙화 AI 프로젝트의 차이점은 무엇인가요?',
    text4_1: '다음과 같은 차이점이 있습니다:',
    text4_2: '탈중앙화 AI 프로젝트는 중앙화된 주체에 의존하지 않으며, 그 토큰은 유틸리티 토큰으로, 증권법의 규제를 받지 않습니다. 반면에 반 탈중앙화 AI 프로젝트의 토큰은 증권형 토큰으로, 각국의 증권법 규제를 받으며 여러 잠재적인 위험이 있을 수 있습니다.',
    text4_3: '탈중앙화 AI 프로젝트는 수명이 더 길며, 팀에 문제가 발생할 경우 커뮤니티가 프로젝트를 인수할 수 있습니다. 왜냐하면 모델은 팀의 중앙화 GPU 서버가 아닌, 채굴자들이 제공하는 GPU 자원 위에서 실행되기 때문입니다.',
    title5: '탈중앙화 AI 제품의 장점은 무엇인가요?',
    text5_1: '1. 프로젝트 팀은 무료로 GPU를 사용할 수 있습니다.',
    text5_2: '2. GPU 채굴 노드를 판매하여 자금을 조달할 수 있습니다.',
    text5_3: '3. 토큰은 유틸리티 토큰이며, 증권법의 규제를 받지 않습니다.',
    text5_4: '4. 토큰을 통해 사용자를 인센티브화할 수 있습니다.',
    text5_5: '5. 모델이 탈중앙화 방식으로 실행되며 투명하고 규제를 받지 않으므로 사용자는 사용 제한에 대해 걱정할 필요가 없습니다.',
    text5_6: '6. 사용자는 프로젝트 관리에 참여할 수 있으며, 팀이 해체되더라도 커뮤니티가 프로젝트를 인수하여 모델 서비스가 중단되지 않습니다.',
  },
  developAi: {
    title: '탈중앙화 AI 제품을 개발하는 방법?',
    title1: '1. 탈중앙화 AI 제품을 개발하는 방법은?',
    text1: '탈중앙화 AI 제품은 주로 결제 정산과 모델 실행 두 부분으로 나뉩니다. 결제 정산은 결제 계약을 배포하여 구현됩니다. 모델 실행은 채굴 보상 계약과 AI 컨테이너의 두 부분으로 나뉘며, 채굴 보상 계약은 GPU 채굴자들에게 보상으로 제공될 토큰의 비율을 결정하여 더 많은 채굴자들이 프로젝트의 모델을 배포하고 토큰을 얻을 수 있도록 장려합니다. AI 컨테이너는 프로젝트 팀이 AI 모델을 컨테이너 환경으로 패키징하여 컨테이너 이미지로 만들고, 채굴자들은 제공된 문서를 기반으로 AI 컨테이너를 배포할 수 있습니다.',
    title2: '2. 결제 정산 계약에 포함되는 내용은?',
    text2_1: '1). 제품의 요금 정책, 예를 들어 요청 횟수에 따른 요금 또는 월 구독 요금.',
    text2_2: '2). 제품의 가격.',
    text2_3: '3). 특정 지갑 주소가 제품을 사용할 수 있는 권한.',
    title3: '3. 채굴 보상 계약에 포함되는 내용은?',
    text3_1: '1). 토큰 보상의 총량.',
    text3_2: '2). 채굴 메커니즘, 예를 들어 매년 채굴할 수 있는 토큰의 수량.',
    text3_3: '3). 채굴된 토큰의 양에 영향을 미치는 요소들, 예를 들어 GPU 성능, 스테이킹된 토큰의 양, 노드 NFT의 수량, 채굴 시간 등.',
    text3_4: '4). 채굴된 토큰의 잠금 해제 규칙.',
    title4: '4. AI 모델 컨테이너에 포함되는 내용은?',
    text4_1: '1). 컨테이너 실행 환경.',
    text4_2: '2). AI 모델과 실행 스크립트.',
    text4_3: '3). DBC GPU 컴퓨팅 노드 등록 스크립트.',
    text4_4: '4). DBC 건강 모니터링 프로그램.',
    text4_5: '5). 채굴 계약 및 결제 정산 계약과의 상호작용 프로그램.',
  },
  deployToken: {
    title: '토큰 스마트 계약을 배포하는 방법?',
    text1: '1. DBC는 ETH EVM과 호환되며, DRC20 토큰 표준은 ERC20과 완전히 호환됩니다. Solidity 언어를 사용하여 스마트 계약을 작성할 수 있습니다.',
    text2_1: '2. DBC 테스트넷 브라우저:',
    text2_2: ' DBCScan을 통해 배포된 계약, 지갑 주소 및 거래를 조회할 수 있습니다.',
    text3: '3. 구체적인 계약 배포 절차:',
    text3_1: '1). 이 dbc 테스트넷 설정을 hardhat.config.ts에 입력하세요. 그런 다음 코드를 작성하고 계약을 테스트할 수 있습니다.',
    text3_2: 'rpc_url: "https://rpc-testnet.dbcwallet.io"',
    text3_3: 'api_url: "https://test.dbcscan.io/api"',
    text3_4: 'browser_url: "https://test.dbcscan.io"',
    text3_5: 'network: "dbcTestnet"',
    text3_6: 'chain_id: 19850818',
    text3_7: '2). Makefile을 사용하여 계약을 컴파일, 배포, 업그레이드, 검증 또는 테스트할 수 있습니다.',
    text3_8: '3). ERC20 계약 개발에 유용할 수 있는 링크들:',
    text4: '4. 토큰 계약 참고 사례:',
  },
  paymentRelated: {
    title: '결제 관련 스마트 계약을 개발하는 방법?',
    text1: '다양한 AI 모델의 가격을 결정하세요. 예를 들어, 현재 프로젝트에는 3개의 AI 모델이 있으며, 월정액과 무료 모드가 있습니다. 무료 모드에서는 각 모델을 하루에 10번 호출할 수 있고, 월정액 모드에서는 하루에 10,000번 호출할 수 있습니다. 각 모델의 가격은 계약 프로그램에서 규정됩니다.',
    text2: '프로젝트 사용자가 DBC AI 분산 네트워크를 통해 프로젝트의 AI 컨테이너로 요청을 전송하면, 컨테이너에는 게이트키퍼 프로그램과 결제 정산 스마트 계약이 상호 작용하여 현재 AI 모델이 사용자에게 계산 서비스를 제공해야 하는지 판단해야 합니다. 이 게이트키퍼 프로그램이 없으면, AI 컨테이너는 모든 사용자 요청에 대해 계산 결과를 반환하며, 사용자는 스마트 계약에 대한 비용을 지불하지 않게 됩니다.',
    text3: '프로젝트 팀의 게이트키퍼 프로그램',
    text4: '프로젝트 팀의 AI 모델 컨테이너',
    text5: '결제 정산 스마트 계약',
    text6: '게이트키퍼 프로그램은 스마트 계약과 상호 작용하여 현재 AI 모델이 사용자에게 계산 서비스를 제공해야 하는지 판단합니다.',
  },
  gpuMining: {
    title: 'GPU 채굴 관련 스마트 계약을 개발하는 방법?',
    text1: 'GPU 채굴 보상 메커니즘은 매우 중요합니다. 이것은 GPU 채굴자가 보상을 받을 수 있는 규칙을 결정합니다. 보상 규칙이 매력적일수록 더 많은 채굴자가 GPU를 제공하여 참여할 것입니다. 일반적으로 토큰의 20%-50%를 채굴 보상으로 할당하는 것이 좋습니다. 2년 또는 4년마다 반감하는 메커니즘을 설정할 수 있습니다. 참고:',
    text2: '채굴자가 GPU 기계에 AI 컨테이너를 배포할 때, 컨테이너에는 스테이킹 프로그램이 포함되어야 합니다. 이 스테이킹 프로그램은 DBC AI Worker Node와 통신하여 등록 또는 해제를 처리합니다. 등록은 해당 기계가 현재 AI 프로젝트의 채굴을 시작했음을 나타냅니다. 또한 스테이킹 프로그램은 GPU 채굴 관련 스마트 계약과 통신하여 등록 성공 정보를 스마트 계약에 보내며, 이때부터 채굴 보상이 카운트되기 시작합니다.',
    img_text1: '등록 / 해제',
    img_text2: 'AI 모델',
    img_text3: '프로젝트 팀의 스테이킹 프로그램',
    img_text4: '프로젝트 팀의 AI 모델 컨테이너',
    img_text5: '등록 정보를 자동으로 DBC 체인에 제출',
    img_text6: '등록 성공 정보를 스마트 계약에 전송',
    img_text7: 'GPU 채굴 관련 계약은 DBC 체인에서 등록 여부를 확인해야 함',
    img_text8: 'GPU 채굴 관련 스마트 계약',
    text3_1: 'GPU 기계가 DBC 네트워크에 가입하는 두 가지 모드가 있습니다:',
    text3_2: '1. 장기 임대 모드  2. 자유 모드',
    text3_3: 'GPU 채굴 스마트 계약은 DBC 체인에서 정보를 가져와 GPU 기계가 어떤 모드로 가입되었는지 판단해야 하며, 모드에 따라 다르게 처리해야 합니다.',
    text4: '장기 임대 모드 처리:',
    text4_1: '1) GPU 채굴 스마트 계약은 먼저 GPU 기계가 이미 임대되었는지 확인해야 합니다.',
    text4_2: '2) 스테이킹 지갑 주소는 DBC 네트워크에서 GPU를 임대한 지갑 주소와 동일한 소유자임을 증명해야 합니다.',
    text4_3: '3) 위 조건이 충족되었을 때만 채굴자에게 토큰 보상이 제공됩니다.',
    text5: '자유 모드 처리:',
    text5_1: '1) GPU 채굴 스마트 계약은 먼저 GPU 기계가 DBC 네트워크에 성공적으로 가입되었는지 확인해야 합니다.',
    text5_2: '2) 위 조건이 충족되었을 때만 채굴자에게 토큰 보상이 제공됩니다.',
  },
  developAIModel: {
    title: 'AI 모델 컨테이너를 개발하는 방법?',
    title1: '1. AI 모델 컨테이너는 AI 프로젝트 팀이 개발하고 GPU 채굴자가 배포합니다.',
    title2: '2. AI 모델 컨테이너에는 무엇이 포함되나요?',
    text2_1: '1) 컨테이너 실행 환경: 먼저 깨끗한 컨테이너를 시작합니다. ',
    text2_1_1: '(컨테이너 이미지 생성 참조 튜토리얼)',
    text2_2: '2) AI 모델 및 실행 스크립트: 모델은 일반적으로 크기 때문에 컨테이너에 미리 다운로드하지 않는 것이 좋습니다. 대신, 컨테이너가 시작된 후 자동으로 원격 소스에서 다운로드하도록 설정할 수 있습니다. 예: Huggingface.',
    text2_3: '3) DBC GPU 계산 노드 등록 스크립트: 이 스크립트는 등록 및 등록 취소를 처리합니다. 컨테이너가 시작될 때 자동으로 등록하고 중지될 때 자동으로 등록 취소되도록 설정할 수도 있습니다.',
    text2_3_1: '(Worker 노드 사용 튜토리얼)',
    text2_4: '4) DBC 건강 모니터링 프로그램. ',
    text2_5: '5) 채굴 계약 및 결제 정산 계약과의 상호 작용 프로그램: 주로 스테이킹 프로그램과 게이트키퍼 프로그램을 포함하며, 두 프로그램을 하나로 통합할 수도 있습니다.',
    block_text1: 'AI 모델',
    block_text2: '프로젝트 팀의 스테이킹 프로그램',
    block_text3: 'DBC 건강 모니터링 프로그램',
    block_text4: '프로젝트 팀의 게이트키퍼 프로그램',
    block_text5: 'DBC GPU 계산 노드의 등록 스크립트',
    block_text6: 'AI 모델 컨테이너',
    title3: '3. AI 모델 컨테이너 이미지를 만드는 방법: 이미지 제작 과정',
  },
  fullProcessTesting: {
    title: '전체 프로세스 테스트를 수행하는 방법?',
    text1: '1. 결제 관련 스마트 계약의 개발을 완료합니다.',
    text2: '2. GPU 채굴 관련 스마트 계약의 개발을 완료합니다.',
    text3: '3. AI 컨테이너 이미지를 생성하는 작업을 완료합니다.',
    text4: '4. AI 모델을 정상적으로 실행할 수 있는 GPU 머신을 준비한 후, 자유 임대 모드를 통해 DBC 네트워크에 추가합니다. 그런 다음 GPU 머신에서 DBC AI Worker Node를 실행하고, AI 컨테이너를 시작한 후 등록합니다.',
    text5: '5. IP 주소가 있는 CPU 머신을 준비한 후, DBC AI Input Node를 CPU 머신에 배포합니다.',
    text6: '6. DBC AI Input Node가 AI 컨테이너를 정상적으로 감지하는지 테스트합니다.',
    text7: '7. 사용자 요청을 DBC AI Input Node에 전송하고, 올바른 피드백 결과가 반환되는지 확인합니다.',
  },
  primaryProcessTesting: {
    title: '초기 프로세스 테스트를 수행하는 방법?',
    text: '초기 프로세스 테스트는 AI 컨테이너가 분산 네트워크를 통해 사용자 요청을 정상적으로 처리하고, 분산 네트워크를 통해 AI 모델의 계산 결과를 정상적으로 반환할 수 있는지 여부를 테스트합니다.',
    text1: '1. AI 컨테이너 이미지 제작을 완료합니다.',
    text2: '2. AI 모델을 정상적으로 실행할 수 있는 GPU 머신을 준비한 후, GPU 머신에서 DBC AI Worker Node(테스트 네트워크 사용)를 실행합니다. 그런 다음 컨테이너 환경을 설치하고, AI 컨테이너를 시작한 후 등록합니다.',
    text3: '3. IP 주소가 있는 CPU 머신을 준비한 후, CPU 머신에 DBC AI Input Node를 배포합니다.',
    text4: '4. DBC AI Input Node가 AI 컨테이너를 정상적으로 감지하는지 테스트합니다.',
    text5: '5. 사용자 요청을 DBC AI Input Node에 전송하고, 올바른 피드백 결과가 반환되는지 확인합니다.',
  },
  participateDBCMining: {
    title: 'DBC 토큰 채굴에 참여하는 방법?',
    text: 'DBC 채굴에만 참여할 경우 얻는 수익은 다음과 같습니다: DBC 채굴 보상 수익 + DBC 임대 수익.',
    text1: 'DBC 요구 사항을 충족하는 GPU 머신을 준비하세요. 자세한 사항은 다음을 참조하세요:',
    text2: 'DBC 토큰 채굴에만 참여할 경우, DBC 채굴 보상 수익과 DBC 임대 수익을 받습니다 (네트워크의 GPU 수가 10,000개 이하일 때만 임대 수익이 있습니다).',
    text3_1: '채굴 수익 조회는 다음을 참조하세요:',
    text3_2: '머신의 월별 수익 계산 공식',
  },
  participateDBCDLCMining: {
    title: 'DBC와 DeepLink 듀얼 채굴에 참여하는 방법?',
    text: 'DBC와 DeepLink 듀얼 채굴에 참여하여 얻는 수익은 다음과 같습니다: DBC 채굴 보상 - 소각된 DBC 임대료 + DeepLink 채굴 보상.',
    text1: 'DeepLink 요구 사항을 충족하는 GPU 머신을 준비하세요. 자세한 사항은 다음을 참조하세요: ',
    text2: 'GPU 머신을 장기 임대 모드로 DBC 네트워크에 먼저 추가한 다음, 스스로 해당 머신을 임대하세요 (DBC에서 임대 상태인 머신은 30% 추가 보상을 받습니다). 그런 다음 DeepLink 네트워크에 참여하세요. ',
    text3_1: '채굴 수익 조회는 다음을 참조하세요:',
    text3_2: '머신의 월별 수익 계산 공식.',
  },
  participateDBCAiMining: {
    title: 'DBC와 AI 토큰 다중 채굴에 참여하는 방법?',
    text: 'DBC와 AI 토큰 다중 채굴에 참여하여 얻는 수익은 다음과 같습니다: DBC 채굴 보상 - 소각된 DBC 임대료 + AI 토큰 보상.',
    text1: 'AI 프로젝트의 요구 사항을 충족하는 GPU 머신을 준비하세요. ',
    text2: 'GPU 머신을 장기 임대 모드로 DBC 네트워크에 먼저 추가한 다음, 스스로 해당 머신을 임대하세요 (DBC에서 임대 상태인 머신은 30% 추가 보상을 받습니다). 그런 다음 AI 프로젝트 채굴 스마트 계약에 참여하세요.',
    text3_1: '채굴 수익 조회는 각 AI 프로젝트의 공식 웹사이트를 참조하세요:',
    text3_2: '머신의 월별 수익 계산 공식.',
  },
  participateAiMining: {
    title: 'AI 토큰 채굴에만 참여하는 방법?',
    text: 'AI 토큰 채굴에만 참여할 경우 얻는 수익은 다음과 같습니다: AI 토큰 보상.',
    text1: 'AI 프로젝트의 요구 사항을 충족하는 GPU 머신을 준비하세요. 자세한 사항은 다음을 참조하세요:',
    text2: 'GPU 머신을 자유 모드로 DBC 네트워크에 먼저 추가한 다음, AI 프로젝트 채굴 스마트 계약에 참여하세요. ',
    text3_1: '채굴 수익 조회는 각 AI 프로젝트의 공식 웹사이트를 참조하세요:',
    text3_2: '머신의 월별 수익 계산 공식.',
  },
  participateGPUMining: {
    title: 'DBC와 기타 GPU 토큰 다중 채굴에 참여하는 방법?',
    text1: 'DBC 요구 사항을 충족하는 GPU 머신을 준비하세요. 자세한 사항은 다음을 참조하세요:',
    text2: 'GPU 머신을 장기 임대 모드로 DBC 네트워크에 먼저 추가한 후 가상 머신을 시작하세요. 그런 다음 가상 머신에서 Aleo, IONET, Aethir 등의 GPU 프로젝트를 시작하세요. 하지만 이 모드에서는 DBC 머신이 사용자에게 임대되면 가상 머신이 언제든지 종료될 수 있으므로, 페널티 메커니즘이 있는 채굴 프로젝트에는 적합하지 않습니다.',
    text3: 'GPU 머신을 장기 임대 모드로 DBC 네트워크에 추가한 후 스스로 해당 머신을 임대하세요 (DBC에서 임대 상태인 머신은 30% 추가 보상을 받습니다). 그런 다음 가상 머신을 시작하고 Aleo, IONET, Aethir 등의 GPU 프로젝트를 시작하세요. 이 모드에서는 가상 머신이 종료되지 않으므로 모든 모드의 GPU 채굴 프로젝트에 적합합니다. 그러나 이 모드는 DBC 임대 수익이 소각되므로 DBC 보상이 임대 소각 손실보다 클 때만 수익성이 있습니다.',
    text4_1: 'DBC 채굴 수익 조회는 다음을 참조하세요:',
    text4_2: '\n 머신의 월별 수익 계산 공식.',
  },
  footer: {
    logo: '로고 다운로드',
    server: '서비스 이메일'
  },
}